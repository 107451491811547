<template>
  <b-row v-if="loaded">
    <b-col
      col
      xl="4"
      lg="3"
      md="12"
      sm="12"
      xs="12"
      class="mt-1"
      v-if="cyber.series.length > 0"
    >
      <chart
        :title="$t('tables.detail.user.awarenessEvolution')"
        :series="[
          {
            name: $t('generic.modules.cybersecurity.title'),
            data: cyber.series
          }
        ]"
        :categories="cyber.labels"
        :dates="cyber.dates"
        :discrete="cyber.discrete"
        :colors="{
          light: ['#e0c3d5'],
          normal: ['#ce93b8']
        }"
        :height="365"
      />
    </b-col>
    <b-col col cols="auto" xl="8" class="mt-1" v-if="cyber.series.length > 0">
      <heat-map
        :series="cyber.heatmap.series"
        :title="$t('tables.detail.user.heatmap')"
        :dimensions="dimensions"
      />
    </b-col>
    <b-col v-if="cyber.series.length === 0">
      <empty-card type="user.cybersecurity" />
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment-timezone";
import _ from "lodash";

import { extractValueFromProfile } from "@/utils/helpers";
import { getProfile } from "@/api/controller/traits.api";
import { getConstants } from "@/api/misc.api";

import Chart from "@/components/users/Chart.component";
import HeatMap from "@/components/users/HeatMap.component";
import EmptyCard from "@/components/EmptyCard.component";

export default {
  name: "Awareness",
  props: {
    stakeholderId: {
      type: String,
      required: true
    },
    interactions: {
      type: Array,
      required: true
    }
  },
  components: {
    Chart,
    HeatMap,
    EmptyCard
  },
  data() {
    return {
      cyber: {},
      profile: {},
      actions: [],
      dimensions: [
        "DIMENSION_ELEMENT_DATAPROTECTION",
        "DIMENSION_ELEMENT_MALWARE",
        "DIMENSION_ELEMENT_COMMUNICATIONS",
        "DIMENSION_ELEMENT_PASSWORDMANAGEMENT",
        "DIMENSION_ELEMENT_WORKPLACE",
        "DIMENSION_ELEMENT_SOCIALENGINEERING",
        "DIMENSION_ELEMENT_COMPLIANCE"
      ],
      loaded: false
    };
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"])
  },
  mounted() {
    var cyberProfile = {
      groupDimensions: [this.getConstant("DIMENSION_GROUP_CIBERSECURITY")],
      filterDate: "NO"
    };
    axios
      .all([
        getProfile(this.stakeholderId, cyberProfile),
        getConstants("SURVEY_KYMATIO_CYBERSECURITY")
      ])
      .then(
        axios.spread((cyberProfileResp, constantsResp) => {
          this.cyber.average = extractValueFromProfile(
            cyberProfileResp.data.records,
            "DIMENSION_GROUP_CIBERSECURITY",
            "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
            "dimensionValue",
            this.interactions,
            "DIMENSION_ELEMENT_CIBER_RESULTS"
          );
          this.cyber.series = [];
          this.cyber.labels = [];
          this.cyber.dates = [];
          this.cyber.discrete = [];
          this.cyber.heatmap = {
            series: []
          };
          var lastElement = 0;
          _.each(_.keys(constantsResp.data.records), survey => {
            if (
              survey !== "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL" &&
              this.getConstant(survey)
            ) {
              const label = _.find(this.interactions, e => {
                return (
                  parseInt(e.surveyTypeId) ===
                  parseInt(this.getConstant(survey))
                );
              });
              const element = extractValueFromProfile(
                cyberProfileResp.data.records,
                "DIMENSION_GROUP_CIBERSECURITY",
                survey,
                "dimensionValue",
                this.interactions,
                "DIMENSION_ELEMENT_CIBER_RESULTS"
              );
              var calendar = label
                ? moment(label.dateStatus).format("ll")
                : false;
              if (element !== false && element !== undefined) {
                this.cyber.series.push(element);
                this.cyber.labels.push(label ? label.surveyName : "");
                this.cyber.dates.push(label ? calendar : false);
                lastElement = element;
              }
              if (label && element !== false) {
                var serie = {
                  name: label ? calendar : "",
                  date: moment(label.dateStatus).format("YYYYMMDDhhmmss"),
                  data: []
                };
                var toAdd = false;
                _.each(this.dimensions, dimension => {
                  const element = extractValueFromProfile(
                    cyberProfileResp.data.records,
                    "DIMENSION_GROUP_CIBERSECURITY",
                    survey,
                    "dimensionValue",
                    this.interactions,
                    dimension
                  );
                  if (element !== false && element !== undefined) {
                    serie.data.push(element / 10);
                    toAdd = true;
                  }
                });
                if (toAdd) this.cyber.heatmap.series.push(serie);
              }
            }
          });
          if (this.cyber.series.length === 1) {
            this.cyber.series.unshift(lastElement);
            this.cyber.labels.unshift("");
            this.cyber.dates.unshift(null);
            this.cyber.series.push(lastElement);
            this.cyber.labels.push("");
            this.cyber.dates.push(null);
            this.cyber.discrete = [
              {
                seriesIndex: 0,
                dataPointIndex: 0,
                size: 0
              },
              {
                seriesIndex: 0,
                dataPointIndex: 2,
                size: 0
              }
            ];
          } else {
            this.cyber.heatmap.series = _.orderBy(
              this.cyber.heatmap.series,
              ["date"],
              ["desc"]
            );
          }
          this.loaded = true;
        })
      );
  }
};
</script>
