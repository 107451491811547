<template>
  <div id="achievements-alerts">
    <div
      :class="
        `d-flex align-items-center pb-3 is-link text-hover-${alert.level}`
      "
      v-for="alert in alerts"
      :key="alert.achievementId"
      @click="goToDetail()"
    >
      <!--begin::Content-->
      <div class="d-flex align-items-center flex-grow-1 cursor-pointer">
        <!--begin::Checkbox-->
        <div :class="`symbol symbol-45 symbol-white mr-4 flex-shrink-0`">
          <div class="symbol-label">
            <span :class="`svg-icon svg-icon-2x`">
              <!--begin::Svg-->
              <i class="fa fa-trophy" style="font-size: 2rem;"></i>
              <!--end::Svg Icon-->
            </span>
            <span
              style="position: absolute; right: 0; top: 0; font-size: 0.7rem;"
              :class="
                `has-badge font-size-xs label label-pill font-weight-bolder label-success`
              "
            >
              {{ alert.total }}
            </span>
          </div>
        </div>
        <!--end::Checkbox-->
        <!--begin::Section-->
        <div
          class="d-flex flex-wrap align-items-center justify-content-between w-100 text-left"
        >
          <!--begin::Info-->
          <div class="d-flex flex-column align-items-cente py-2 w-auto">
            <!--begin::Title-->
            <span
              :class="`text-dark-75 font-weight-bold font-size-lg mb-1`"
              v-html="
                $tc(
                  `dashboard.alerts.interaction.${alert.type}.${alert.subtype}`,
                  { total: alert.total }
                )
              "
            />
            <!--end::Title-->
          </div>
          <!--end::Info-->
        </div>
        <!--end::Section-->
      </div>
      <div class="ml-2" v-if="alert.total && link">
        <b-button
          tag="div"
          @click="goToDetail"
          :class="`bg-transparent border-0 svg-icon svg-icon-2x`"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 01-1.474-1.352l4.853-5.294-5.323-5.323z" fill="#000" fill-rule="nonzero"/><path d="M5.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 11-1.474-1.352l4.853-5.294-5.323-5.323z" fill="#000" fill-rule="nonzero" opacity=".3"/></g></svg>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "AchievementsAlerts",
  props: {
    achievements: {
      type: Array
    },
    link: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    alerts() {
      let id = localStorage.getItem("stakeholderId");
      let result = [];
      const newAchievements = _.filter(this.achievements, achievement => {
        return (
          achievement.stakeholders[id] &&
          achievement.stakeholders[id].tradeMissing == 1
        );
      }).length;

      if (newAchievements) {
        result.push({
          type: "user",
          subtype: "achievements",
          level: "warning",
          total: newAchievements
        });
      }

      return result;
    }
  },
  methods: {
    goToDetail() {
      if (this.link) {
        this.$router.push({ name: "Achievements" });
      }
    }
  }
};
</script>
