var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"user-breaches"}},[_c('b-card',{staticClass:"card-custom card-stretch card-rounded",attrs:{"no-body":""}},[_c('b-card-body',[(_vm.breaches.length)?_c('b-table',{attrs:{"id":"user-breaches-table","hover":"","fields":_vm.fields,"items":_vm.breaches,"responsive":"sm","stacked":"sm","per-page":_vm.perPage,"current-page":_vm.currentPage,"small":""},scopedSlots:_vm._u([{key:"cell(logo)",fn:function(row){return [_c('div',{staticClass:"logo-container",style:('background-image: url(' + row.item.breach.logoPath + ')')})]}},{key:"cell(name)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.breach.name)+" ")]}},{key:"cell(title)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.breach.title)+" ")]}},{key:"cell(domain)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.breach.domain)+" ")]}},{key:"cell(dataClasses)",fn:function(row){return [_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((row.item.breach.dataClasses),function(dataClass,index){return _c('div',{key:index},[_c('label',{class:`label label-inline mr-2 mb-2 font-weight-bolder ${
                    dataClass === 'Passwords'
                      ? 'label-light-danger'
                      : dataClass === 'Email addresses'
                      ? 'label-light-warning'
                      : 'label-default'
                  }`,attrs:{"variant":"primary"}},[_vm._v(_vm._s(dataClass))])])}),0)]}},{key:"cell(solved)",fn:function(row){return [_c('div',{staticClass:"text-center"},[(row.item.stakeholders[0].status.solved)?_c('b-icon-check',{attrs:{"variant":"success","scale":"2"}}):_c('b-icon-exclamation-triangle',{attrs:{"scale":"2","variant":"danger"}})],1)]}},{key:"cell(breachDate)",fn:function(row){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(row.item.breach.breachDate)+" ")])]}}],null,false,3358080575)}):_vm._e(),(_vm.total > 0)?_c('div',{staticClass:"flex-wrap mx-auto"},[_c('b-pagination',{staticClass:"d-flex flex-wrap justify-content-center align-items-center mt-3 py-2 mr-3",attrs:{"total-rows":_vm.total,"per-page":_vm.perPage,"aria-controls":"companies-table","first-number":"","pill":"","last-number":"","size":"lg"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_c('empty-card')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }