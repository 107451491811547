<script>
import EmptyCard from "@/components/EmptyCard.component";
import Tabs from "@/components/users/Tabs.component";
import { mapGetters } from "vuex";

import { getStakeholderActions } from "@/api/controller/traits.api";

import _ from "lodash";

export default {
  name: "Archetype",
  props: {
    stakeholderId: {
      type: String,
      required: true
    }
  },
  components: {
    EmptyCard,
    Tabs
  },
  data() {
    return {
      arch: [],
      loaded: false
    };
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"])
  },
  mounted() {
    var actionParams = {
      surveyTypes: [this.getConstant("SURVEY_KYMATIO_ARCHETYPE_GLOBAL")],
      groupDimensions: [this.getConstant("DIMENSION_GROUP_ARCHETYPES")],
      actionTypes: [
        this.getConstant("ACTIONS_TYPE_ARCHETYPES_DESCRIPTION"),
        this.getConstant("ACTIONS_TYPE_ARCHETYPES_MOTIVATION"),
        this.getConstant("ACTIONS_TYPE_ARCHETYPES_WORK"),
        this.getConstant("ACTIONS_TYPE_ARCHETYPES_STRENGTHS"),
        this.getConstant("ACTIONS_TYPE_ARCHETYPES_CHALLENGES"),
        this.getConstant("ACTIONS_TYPE_ARCHETYPES_TELEWORKING")
      ],
      entityType: this.getConstant("ENTITY_TYPE_USER"),
      showExternal: true
    };

    getStakeholderActions(this.stakeholderId, actionParams).then(archResp => {
      _.forEach(archResp.data.records, element => {
        if (
          parseInt(element.actionTypeId) ===
          parseInt(this.getConstant("ACTIONS_TYPE_ARCHETYPES_DESCRIPTION"))
        ) {
          element.icon = "flaticon2-chat-1";
        } else if (
          parseInt(element.actionTypeId) ===
          parseInt(this.getConstant("ACTIONS_TYPE_ARCHETYPES_MOTIVATION"))
        ) {
          element.icon = "flaticon2-drop";
        } else if (
          parseInt(element.actionTypeId) ===
          parseInt(this.getConstant("ACTIONS_TYPE_ARCHETYPES_WORK"))
        ) {
          element.icon = "flaticon2-shield";
        } else if (
          parseInt(element.actionTypeId) ===
          parseInt(this.getConstant("ACTIONS_TYPE_ARCHETYPES_STRENGTHS"))
        ) {
          element.icon = "fa fa-dumbbell";
        } else if (
          parseInt(element.actionTypeId) ===
          parseInt(this.getConstant("ACTIONS_TYPE_ARCHETYPES_TELEWORKING"))
        ) {
          element.icon = "fas fa-laptop-house";
        } else {
          element.icon = "fa fa-mountain";
        }
        this.arch.push(element);
      });
      this.loaded = true;
    });
  }
};
</script>

<template>
  <b-row v-if="loaded">
    <b-col col cols="12" v-if="arch.length > 0">
      <tabs :tabs="arch" :title="$t('tables.archetype')" />
    </b-col>
    <b-col
      v-if="
        arch.length === 0 &&
          actions.length === 0 &&
          wellbeing.labels &&
          wellbeing.labels.length === 0
      "
    >
      <empty-card type="user.wellbeing" />
    </b-col>
  </b-row>
</template>
