<template>
  <b-row v-if="loaded">
    <b-col
      col
      xl="4"
      lg="3"
      md="12"
      sm="12"
      xs="12"
      class="mt-1"
      v-if="risk.length > 0"
    >
      <radar-chart :title="$t('generic.modules.insider.chart')" :data="risk" />
    </b-col>
    <b-col
      col
      cols="auto"
      xl="8"
      lg="9"
      md="12"
      sm="12"
      xs="12"
      class="mt-1"
      v-if="actions.length > 0"
    >
      <tabs :tabs="actions" />
    </b-col>
    <b-col v-if="actions.length === 0 && risk.length === 0">
      <empty-card type="user.insider" />
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import _ from "lodash";

import { extractValueFromProfile } from "@/utils/helpers";
import { getProfile, getStakeholderActions } from "@/api/controller/traits.api";

import Tabs from "@/components/users/Tabs.component";
import RadarChart from "@/components/users/RadarChart.component";
import EmptyCard from "@/components/EmptyCard.component";

export default {
  name: "Insider",
  props: {
    stakeholderId: {
      type: String,
      required: true
    },
    interactions: {
      type: Array,
      required: true
    }
  },
  components: {
    Tabs,
    RadarChart,
    EmptyCard
  },
  data() {
    return {
      risk: [],
      actions: [],
      dimensions: [
        "DIMENSION_ELEMENT_COMMITMENT",
        "DIMENSION_ELEMENT_TRUST",
        "DIMENSION_ELEMENT_CHALLENGE",
        "DIMENSION_ELEMENT_PRAGMATISM",
        "DIMENSION_ELEMENT_DISSATISFACTION",
        "DIMENSION_ELEMENT_NEGLIGENCE",
        "DIMENSION_ELEMENT_EXPEDITION",
        "DIMENSION_ELEMENT_OVERLOAD",
        "DIMENSION_ELEMENT_DIVERGENCE",
        "DIMENSION_ELEMENT_AMBITION"
      ],
      loaded: false
    };
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"])
  },
  mounted() {
    var riskParams = {
      surveyTypes: [this.getConstant("SURVEY_KYMATIO_GRI_GLOBAL")],
      groupDimensions: [this.getConstant("DIMENSION_GROUP_INSIDER")]
    };
    var riskActionParams = {
      surveyTypes: [this.getConstant("SURVEY_KYMATIO_GRI_GLOBAL")],
      groupDimensions: [this.getConstant("DIMENSION_GROUP_INSIDER")],
      actionTypes: [this.getConstant("ACTIONS_TYPE_TEXT_WELFARE")],
      entityType: this.getConstant("ENTITY_TYPE_USER"),
      showExternal: true,
      maxValue: "YES"
    };
    axios
      .all([
        getProfile(this.stakeholderId, riskParams),
        getStakeholderActions(this.stakeholderId, riskActionParams)
      ])
      .then(
        axios.spread((riskProfileResp, actionsResp) => {
          this.actions = actionsResp.data.records;
          _.foreach(this.dimensions, dimension => {
            var value = extractValueFromProfile(
              riskProfileResp.data.records,
              "DIMENSION_GROUP_INSIDER",
              "SURVEY_KYMATIO_GRI_GLOBAL",
              "dimensionValue",
              this.interactions,
              dimension
            );
            if (value !== false && value > 0) {
              this.risk.push({
                label: this.$t(
                  `tables.dimensionIds.dimension.${this.getConstant(
                    dimension
                  )}.DEFAULT.title`
                ),

                value: value
              });
            }
          });
          this.loaded = true;
        })
      );
  }
};
</script>
