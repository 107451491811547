<template>
  <b-row v-if="loaded">
    <b-col col cols="12" class="mt-1" v-if="actions.length > 0">
      <tabs :tabs="actions" />
    </b-col>
    <b-col v-else>
      <empty-card type="user.social" />
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import _ from "lodash";

import { getStakeholderActions } from "@/api/controller/traits.api";

import Tabs from "@/components/users/Tabs.component";
import EmptyCard from "@/components/EmptyCard.component";

export default {
  name: "Awareness",
  props: {
    stakeholderId: {
      type: String,
      required: true
    },
    interactions: {
      type: Array,
      required: true
    }
  },
  components: {
    Tabs,
    EmptyCard
  },
  data() {
    return {
      actions: [],
      loaded: false
    };
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"])
  },
  mounted() {
    var cyberActionParams = {
      surveyTypes: [
        this.getConstant("SURVEY_KYMATIO_SOCIAL_ENGINEERING_GLOBAL")
      ],
      groupDimensions: [this.getConstant("DIMENSION_GROUP_SOCIAL_ENGINEERING")],
      actionTypes: [
        this.getConstant("ACTIONS_TYPE_SE_NAME"),
        this.getConstant("ACTIONS_TYPE_SE_TYPE_RECOM")
      ],
      entityType: this.getConstant("ENTITY_TYPE_USER"),
      showExternal: false
    };
    axios
      .all([getStakeholderActions(this.stakeholderId, cyberActionParams)])
      .then(
        axios.spread(actionsResp => {
          this.actions = _.filter(actionsResp.data.records, action => {
            return (
              parseInt(action.actionTypeId) ===
              parseInt(this.getConstant("ACTIONS_TYPE_SE_TYPE_RECOM"))
            );
          });
          this.loaded = true;
        })
      );
  }
};
</script>
