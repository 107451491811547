<template>
  <div>
    <b-row v-if="loaded">
      <b-col
        col
        xl="4"
        lg="3"
        md="12"
        sm="12"
        xs="12"
        v-if="wellbeing.labels.length > 0"
      >
        <div class="wellbeing-cart-container h-100">
          <chart
            class=""
            :title="
              $t('tables.detail.user.evolution', {
                module: $t('generic.modules.wellbeing.title')
              })
            "
            :series="[
              {
                name: $t('generic.modules.wellbeing.title'),
                data: wellbeing.series
              }
            ]"
            :categories="wellbeing.labels"
            :dates="wellbeing.dates"
            :discrete="wellbeing.discrete"
            :colors="{
              light: ['#cacbec'],
              normal: ['#a1a3d3']
            }"
            :height="350"
          />
        </div>
      </b-col>
      <b-col
        col
        cols="auto"
        xl="8"
        lg="9"
        md="12"
        sm="12"
        xs="12"
        v-if="actions.length > 0"
      >
        <tabs :tabs="actions" />
      </b-col>
      <b-col v-if="actions.length === 0 && wellbeing.labels.length === 0">
        <empty-card type="user.wellbeing" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import _ from "lodash";
import moment from "moment-timezone";

import { extractValueFromProfile } from "@/utils/helpers";
import { getProfile, getStakeholderActions } from "@/api/controller/traits.api";

import Chart from "@/components/users/Chart.component";
import Tabs from "@/components/users/Tabs.component";
import EmptyCard from "@/components/EmptyCard.component";

export default {
  name: "Wellbeing",
  props: {
    stakeholderId: {
      type: String,
      required: true
    },
    interactions: {
      type: Array,
      required: true
    }
  },
  components: {
    Chart,
    Tabs,
    EmptyCard
  },
  data() {
    return {
      wellbeing: {},
      profile: {},
      actions: [],
      loaded: false
    };
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"])
  },
  mounted() {
    var wellbeingParams = {
      groupDimensions: [this.getConstant("DIMENSION_GROUP_WORKING")],
      dimensions: [this.getConstant("DIMENSION_ELEMENT_CLIMATE_LEVEL")],
      filterDate: "NO"
    };
    var wellbeingActionParams = {
      surveyTypes: [this.getConstant("SURVEY_KYMATIO_CLIMATE_GLOBAL")],
      groupDimensions: [this.getConstant("DIMENSION_GROUP_RECOMEND_CLIMATE")],
      actionTypes: [this.getConstant("ACTIONS_TYPE_RECOMMENDATION")],
      entityType: this.getConstant("ENTITY_TYPE_USER"),
      showExternal: true
    };

    axios
      .all([
        getProfile(this.stakeholderId, wellbeingParams),
        getStakeholderActions(this.stakeholderId, wellbeingActionParams)
      ])
      .then(
        axios.spread((wellbeingProfileResp, actionsResp) => {
          this.actions = actionsResp.data.records;
          this.wellbeing.average = extractValueFromProfile(
            wellbeingProfileResp.data.records,
            "DIMENSION_GROUP_WORKING",
            "SURVEY_KYMATIO_CLIMATE_GLOBAL",
            "dimensionValue",
            this.interactions,
            "DIMENSION_ELEMENT_CLIMATE_LEVEL"
          );
          var profile = wellbeingProfileResp.data.records;
          this.wellbeing.series = [];
          this.wellbeing.labels = [];
          this.wellbeing.dates = [];
          this.wellbeing.discrete = [];
          var lastElement = 0;
          if (
            profile[this.getConstant("DIMENSION_GROUP_WORKING")] &&
            profile[this.getConstant("DIMENSION_GROUP_WORKING")] &&
            profile[this.getConstant("DIMENSION_GROUP_WORKING")][
              this.getConstant("DIMENSION_ELEMENT_CLIMATE_LEVEL")
            ]
          ) {
            var global = _.find(this.interactions, element => {
              return (
                element.surveyTypeId ===
                  this.getConstant("SURVEY_KYMATIO_CLIMATE_GLOBAL") &&
                element.userEndDate == this.getConstant("NULL_DATE")
              );
            });
            var surveys =
              profile[this.getConstant("DIMENSION_GROUP_WORKING")][
                this.getConstant("DIMENSION_ELEMENT_CLIMATE_LEVEL")
              ];
            _.forEach(surveys, (survey, surveyId) => {
              if (
                global &&
                parseInt(surveyId) !== parseInt(global.surveyEntityId)
              ) {
                var actualSurvey = _.find(this.interactions, i => {
                  return parseInt(i.surveyEntityId) === parseInt(surveyId);
                });
                _.each(survey, dim => {
                  this.wellbeing.series.push(dim.dimensionValue);
                  lastElement = dim.dimensionValue;
                  var label = _.find(this.interactions, int => {
                    return parseInt(int.surveyEntityId) === parseInt(surveyId);
                  });
                  this.wellbeing.labels.push(label ? label.surveyName : "");
                  this.wellbeing.dates.push(
                    label ? moment(actualSurvey.dateStatus).format("ll") : false
                  );
                });
              }
            });
          }
          if (this.wellbeing.series.length === 1) {
            this.wellbeing.series.unshift(lastElement);
            this.wellbeing.labels.unshift("");
            this.wellbeing.dates.unshift(null);
            this.wellbeing.series.push(lastElement);
            this.wellbeing.labels.push("");
            this.wellbeing.dates.push(null);
            this.wellbeing.discrete = [
              {
                seriesIndex: 0,
                dataPointIndex: 0,
                size: 0
              },
              {
                seriesIndex: 0,
                dataPointIndex: 2,
                size: 0
              }
            ];
          }
          this.loaded = true;
        })
      );
  }
};
</script>
