<template>
  <b-card
    class="card-custom card-stretch gutter-b"
    body-class="p-0 d-flex flex-column"
    header-class="border-0 pt-5"
  >
    <template #header>
      <b-card-title class="font-weight-bolder">
        <div class="card-label">{{ title }}</div>
      </b-card-title>
    </template>
    <div v-if="series.length > 0" class="p-0 m-0">
      <apexchart
        type="heatmap"
        height="350"
        :options="chartOptions"
        :series="series"
      />
    </div>
  </b-card>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "HeatMap",
  props: {
    title: {
      type: String,
      required: true
    },
    series: {
      type: Array,
      required: true
    },
    dimensions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"]),
    chartOptions() {
      let self = this;
      return {
        chart: {
          height: 350,
          type: "heatmap",
          toolbar: {
            show: false
          }
        },
        tooltip: {
          style: {
            fontSize: "12px",
            fontFamily: "Raleway"
          },
          y: {
            formatter: function(val) {
              return val === 1
                ? self.$t("generic.values.correct")
                : val === 0
                ? self.$t("generic.values.incorrect")
                : "?";
            }
          },
          x: {
            show: true
          }
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.7,
            radius: 0,
            useFillColorAsStroke: false,
            colorScale: {
              ranges: [
                {
                  from: 0,
                  to: 0.99,
                  name: this.$t("generic.values.incorrect"),
                  color: "#F64E60"
                },
                {
                  from: 1,
                  to: 2,
                  name: this.$t("generic.values.correct"),
                  color: "#0BB783"
                }
              ]
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          type: "category",
          categories: this.textDimensions
        },
        stroke: {
          width: 1
        }
      };
    },
    textDimensions() {
      let texts = [];
      _.each(this.dimensions, dimension => {
        texts.push(
          this.$t(
            `tables.dimensionIds.dimension.${this.getConstant(
              dimension
            )}.DEFAULT.title`
          )
        );
      });
      return texts;
    }
  }
};
</script>
