<template>
  <b-row v-if="loaded">
    <b-col col cols="auto" class="mt-1" v-if="actions.length > 0">
      <b-card
        class="card-custom card-stretch gutter-b mb-6"
        no-body
        id="user-tabs"
      >
        <div class="card-header border-0 pt-5">
          <div class="card-title text-left">
            {{ $t("generic.modules.burnout.title") }}
          </div>
        </div>
        <div class="card-body d-flex flex-column text-left">
          <span v-for="action in actions" :key="action.dimensionId">
            <span v-html="action.executeAction.description" />
            <div
              class="assets mt-8"
              v-if="
                action.executeAction.assets &&
                  action.executeAction.assets.length > 0
              "
            >
              <b-button
                tag="a"
                class="mr-4 mb-4"
                variant="light"
                v-for="asset in action.executeAction.assets"
                :key="asset.id"
                :class="'asset-' + asset.type"
                @click="downloadFile(asset)"
              >
                <span v-if="asset.type === 'youtube_iframe'">
                  <i class="socicon-youtube text-danger"></i>
                  {{ asset.link }}
                </span>
                <span v-else-if="asset.type === 'ted_iframe'">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 324 119" v-bind:svg-inline="''" v-bind:style="'max-width: 1.1rem; height: auto; margin-right: 3px;'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#E62B1E" d="M32.678 30.831H0V.85h101.56v29.981H68.875V118.3H32.678V30.831zM107.18.85h98.75v29.981h-62.55v14.462h62.55v28.212h-62.55v14.814h62.55V118.3h-98.75V.85zm104.72 0h59.39C310.3.85 324 29.773 324 59.401c0 35.975-18.98 58.899-59.74 58.899H211.9V.85zm36.2 87.469h14.05c22.49 0 25.66-18.337 25.66-29.27 0-7.41-2.46-27.865-28.47-27.865h-11.6l.36 57.135z"/></svg>
                  {{ asset.link }}
                </span>
                <span v-else-if="asset.type === 'vimeo_iframe'">
                  <i class="socicon-vimeo text-danger"></i>
                  {{ asset.link }}
                </span>
                <span v-else>
                  <i :class="'fas fa-file-' + asset.type"></i>
                  {{ asset.link }}
                </span>
              </b-button>
            </div>
          </span>
        </div>
      </b-card>
    </b-col>
    <b-col v-else>
      <empty-card type="user.insider" />
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

import { getStakeholderActions } from "@/api/controller/traits.api";

import EmptyCard from "@/components/EmptyCard.component";

export default {
  name: "Burnout",
  props: {
    stakeholderId: {
      type: String,
      required: true
    }
  },
  components: {
    EmptyCard
  },
  data() {
    return {
      actions: [],
      loaded: false
    };
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"])
  },
  mounted() {
    var params = {
      dimensions: [
        this.getConstant("DIMENSION_ELEMENT_BURNOUT_NOTE"),
        this.getConstant("DIMENSION_ELEMENT_BURNOUT_MEDIUM_NOTE")
      ],
      surveyTypes: [this.getConstant("SURVEY_KYMATIO_BURNOUT_GLOBAL")],
      groupDimensions: [this.getConstant("DIMENSION_GROUP_BURNOUT")],
      actionTypes: [this.getConstant("ACTIONS_TYPE_RECOMMENDATION")],
      showExternal: false,
      maxValue: "NO"
    };
    axios.all([getStakeholderActions(this.stakeholderId, params)]).then(
      axios.spread(actionsResp => {
        this.actions = actionsResp.data.records;
        this.loaded = true;
      })
    );
  }
};
</script>
