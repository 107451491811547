<template>
  <div id="user-breaches">
    <b-card class="card-custom card-stretch card-rounded" no-body>
      <b-card-body>
        <b-table
          v-if="breaches.length"
          id="user-breaches-table"
          hover
          :fields="fields"
          :items="breaches"
          responsive="sm"
          stacked="sm"
          :per-page="perPage"
          :current-page="currentPage"
          small
        >
          <template #cell(logo)="row">
            <div
              class="logo-container"
              :style="'background-image: url(' + row.item.breach.logoPath + ')'"
            ></div>
          </template>
          <template #cell(name)="row">
            {{ row.item.breach.name }}
          </template>
          <template #cell(title)="row">
            {{ row.item.breach.title }}
          </template>
          <template #cell(domain)="row">
            {{ row.item.breach.domain }}
          </template>
          <template #cell(dataClasses)="row">
            <div class="d-flex flex-wrap">
              <div
                v-for="(dataClass, index) in row.item.breach.dataClasses"
                :key="index"
              >
                <label
                  :class="
                    `label label-inline mr-2 mb-2 font-weight-bolder ${
                      dataClass === 'Passwords'
                        ? 'label-light-danger'
                        : dataClass === 'Email addresses'
                        ? 'label-light-warning'
                        : 'label-default'
                    }`
                  "
                  variant="primary"
                  >{{ dataClass }}</label
                >
              </div>
            </div>
          </template>
          <template #cell(solved)="row">
            <div class="text-center">
              <b-icon-check
                variant="success"
                scale="2"
                v-if="row.item.stakeholders[0].status.solved"
              ></b-icon-check>
              <b-icon-exclamation-triangle
                scale="2"
                variant="danger"
                v-else
              ></b-icon-exclamation-triangle>
            </div>
          </template>
          <template #cell(breachDate)="row">
            <div class="text-center">
              {{ row.item.breach.breachDate }}
            </div>
          </template>
        </b-table>

        <div class="flex-wrap mx-auto" v-if="total > 0">
          <b-pagination
            class="d-flex flex-wrap justify-content-center align-items-center mt-3 py-2 mr-3"
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            aria-controls="companies-table"
            first-number
            pill
            last-number
            size="lg"
          ></b-pagination>
        </div>
        <empty-card v-else />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import _ from "lodash";

import EmptyCard from "@/components/EmptyCard.component";
import { BIconCheck, BIconExclamationTriangle } from "bootstrap-vue";

export default {
  name: "UserBreachesList",
  data() {
    return {
      perPage: 5,
      currentPage: 1
    };
  },
  props: {
    stakeholderId: {
      required: true
    },
    breaches: {
      required: true
    }
  },
  components: {
    EmptyCard,
    BIconCheck,
    BIconExclamationTriangle
  },
  methods: {},
  computed: {
    fields() {
      return [
        {
          key: "logo",
          label: "",
          sortable: false,
          sortByFormatted: false,
          class: "align-middle text-left"
        },
        {
          key: "name",
          label: this.$tc("tables.name", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left",
          // eslint-disable-next-line no-unused-vars
          formatter: (value, key, item) => {
            return item.breach.name;
          }
        },
        {
          key: "domain",
          label: this.$tc("tables.domain", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left",
          formatter: (value, key, item) => {
            return item.breach.domain;
          }
        },
        {
          key: "dataClasses",
          label: this.$tc("tables.dataClasses", 1),
          sortable: false,
          sortByFormatted: false,
          class: "align-middle text-left"
        },
        {
          key: "solved",
          label: this.$tc("tables.solved", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left",
          thClass: "th-size",
          formatter: (value, key, item) => {
            return item.stakeholders[0].status.solved;
          }
        },
        {
          key: "breachDate",
          label: this.$tc("tables.breachDate", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-start text-left",
          thClass: ["th-size", "td-display"],
          tdClass: "td-display",
          formatter: (value, key, item) => {
            return item.breach.breachDate;
          }
        }
      ];
    },
    total() {
      return _.size(this.breaches);
    }
  }
};
</script>

<style lang="scss">
.tooltip-dataClasses {
  cursor: pointer;
}

.th-size {
  width: 120px;
}

.td-display {
  display: table-cell !important;
  vertical-align: middle !important;
}

.logo {
  height: 100%;
  width: 100%;
  background-color: #ebedf3;
  padding: 5px;
}

.logo-container {
  margin: 0 1rem;
  padding: 1rem 0;
  height: 3.5rem;
  width: 3.5rem;
  background-repeat: no-repeat;
  background-size: 80%;
  background-color: #ebedf3;
  background-position: center;
}
</style>
