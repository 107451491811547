<template>
  <b-row v-if="loaded">
    <b-col
      col
      xl="4"
      lg="3"
      md="12"
      sm="12"
      xs="12"
      v-if="
        security.risk.labels.length > 0 || security.hazard.labels.length > 0
      "
    >
      <chart
        :title="
          $t('tables.detail.user.evolution', {
            module: $t('tables.risk')
          })
        "
        :series="security.risk.series"
        :categories="security.risk.labels"
        :dates="security.risk.dates"
        :discrete="security.risk.discrete"
        :base="6"
        :half="
          hasModule(['risk']) &&
            security.risk.labels.length > 0 &&
            hasModule(['impact']) &&
            security.hazard.labels.length > 0
        "
        :opacity="true"
        :colors="{
          light: ['#8558409b', '#e6b3759b', '#beb09f9b'],
          normal: ['#855840', '#e6b375', '#beb09f']
        }"
        v-if="hasModule(['risk']) && security.risk.labels.length > 0"
        :height="220"
      />
      <chart
        :title="
          $t('tables.detail.user.evolution', {
            module: $t('tables.hazard')
          })
        "
        :series="security.hazard.series"
        :categories="security.hazard.labels"
        :dates="security.hazard.dates"
        :discrete="security.hazard.discrete"
        :base="3"
        :half="
          hasModule(['risk']) &&
            security.risk.labels.length > 0 &&
            hasModule(['impact']) &&
            security.hazard.labels.length > 0
        "
        :opacity="true"
        :colors="{
          light: ['#8558409b', '#e6b3759b', '#beb09f9b'],
          normal: ['#855840', '#e6b375', '#beb09f']
        }"
        v-if="hasModule(['impact']) && security.hazard.labels.length > 0"
        :height="220"
      />
    </b-col>
    <b-col
      col
      cols="auto"
      xl="8"
      lg="9"
      md="12"
      sm="12"
      xs="12"
      v-if="actions.length > 0 && hasModule(['risk'])"
    >
      <tabs :tabs="actions" />
    </b-col>
    <b-col v-else>
      <empty-card type="user.security" />
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import _ from "lodash";
import moment from "moment-timezone";

import { extractValueFromProfile } from "@/utils/helpers";
import { getProfile, getStakeholderActions } from "@/api/controller/traits.api";

import Chart from "@/components/users/Chart.component";
import Tabs from "@/components/users/Tabs.component";
import EmptyCard from "@/components/EmptyCard.component";

export default {
  name: "Security",
  props: {
    stakeholderId: {
      type: String,
      required: true
    },
    interactions: {
      type: Array,
      required: true
    }
  },
  components: {
    Chart,
    Tabs,
    EmptyCard
  },
  data() {
    return {
      security: {
        risk: {},
        hazard: {}
      },
      profile: {},
      actions: [],
      insiderRadar: [],
      loaded: false
    };
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"]),
    ...mapGetters("Auth", ["hasModule"])
  },
  mounted() {
    var securityParams = {
      groupDimensions: [
        this.getConstant("DIMENSION_GROUP_RISK"),
        this.getConstant("DIMENSION_GROUP_HAZARD"),
        this.getConstant("DIMENSION_GROUP_INSIDER")
      ],
      maxValue: "NO",
      filterDate: "NO"
    };
    var securityActionParams = {
      surveyTypes: [
        this.getConstant("SURVEY_KYMATIO_GRI_GLOBAL"),
        this.getConstant("SURVEY_KYMATIO_GRI_COMMITMENT"),
        this.getConstant("SURVEY_KYMATIO_GRI_TRUST"),
        this.getConstant("SURVEY_KYMATIO_GRI_CHALLENGE"),
        this.getConstant("SURVEY_KYMATIO_GRI_PRAGMATISM"),
        this.getConstant("SURVEY_KYMATIO_GRI_DISSATISFACTION"),
        this.getConstant("SURVEY_KYMATIO_GRI_NEGLIGENCE"),
        this.getConstant("SURVEY_KYMATIO_GRI_EXPEDITION"),
        this.getConstant("SURVEY_KYMATIO_GRI_OVERLOAD"),
        this.getConstant("SURVEY_KYMATIO_GRI_DIVERGENCE"),
        this.getConstant("SURVEY_KYMATIO_GRI_AMBITION")
      ],
      actionTypes: [this.getConstant("ACTIONS_TYPE_RECOMMENDATION")],
      entityType: this.getConstant("ENTITY_TYPE_USER"),
      showExternal: true,
      maxValue: "YES"
    };
    axios
      .all([
        getProfile(this.stakeholderId, securityParams),
        getStakeholderActions(this.stakeholderId, securityActionParams)
      ])
      .then(
        axios.spread((securityProfileResp, actionsResp) => {
          this.actions = actionsResp.data.records;
          this.security.risk.average = extractValueFromProfile(
            securityProfileResp.data.records,
            "DIMENSION_GROUP_RISK",
            "SURVEY_KYMATIO_GRI_GLOBAL",
            "dimensionValue",
            this.interactions,
            "DIMENSION_ELEMENT_GENERAL_RISK"
          );

          var profile = securityProfileResp.data.records;
          this.security.risk.series = [
            { name: this.$t("tables.availability"), data: [] },
            { name: this.$t("tables.integrity"), data: [] },
            { name: this.$t("tables.confidentiality"), data: [] }
          ];

          this.security.hazard.series = [
            { name: this.$t("tables.availability"), data: [] },
            { name: this.$t("tables.integrity"), data: [] },
            { name: this.$t("tables.confidentiality"), data: [] }
          ];

          var riskSurveysTypes = [
            this.getConstant("SURVEY_KYMATIO_INSIDER_GREEN"),
            this.getConstant("SURVEY_KYMATIO_INSIDER_RED"),
            this.getConstant("SURVEY_KYMATIO_INSIDER_BLUE"),
            this.getConstant("SURVEY_KYMATIO_INSIDER_RED_REFRESH"),
            this.getConstant("SURVEY_KYMATIO_INSIDER_GREEN_REFRESH"),
            this.getConstant("SURVEY_KYMATIO_INSIDER_BLUE_REFRESH"),
            this.getConstant("SURVEY_KYMATIO_ARCHETYPE_REFRESH"),
            this.getConstant("SURVEY_KYMATIO_IMPACT_REFRESH")
          ];

          var hazardSurveyTypes = [
            this.getConstant("SURVEY_KYMATIO_INSIDER_GREEN"),
            this.getConstant("SURVEY_KYMATIO_INSIDER_RED"),
            this.getConstant("SURVEY_KYMATIO_INSIDER_RED_REFRESH"),
            this.getConstant("SURVEY_KYMATIO_INSIDER_GREEN_REFRESH"),
            this.getConstant("SURVEY_KYMATIO_IMPACT"),
            this.getConstant("SURVEY_KYMATIO_IMPACT_REFRESH")
          ];

          this.security.risk.labels = [];
          this.security.risk.dates = [];
          this.security.risk.discrete = [];

          this.security.hazard.labels = [];
          this.security.hazard.dates = [];
          this.security.hazard.discrete = [];

          if (
            (profile[this.getConstant("DIMENSION_GROUP_RISK")] &&
              profile[this.getConstant("DIMENSION_GROUP_RISK")][
                this.getConstant("DIMENSION_ELEMENT_AVAILABILITY_RISK")
              ]) ||
            (profile[this.getConstant("DIMENSION_GROUP_HAZARD")] &&
              profile[this.getConstant("DIMENSION_GROUP_HAZARD")][
                this.getConstant("DIMENSION_ELEMENT_AVAILABILITY_HAZARD")
              ])
          ) {
            /* RISK */
            var global = _.find(this.interactions, element => {
              return (
                element.surveyTypeId ===
                  this.getConstant("SURVEY_KYMATIO_GRI_GLOBAL") &&
                element.userEndDate == this.getConstant("NULL_DATE")
              );
            });
            var surveys = profile[this.getConstant("DIMENSION_GROUP_RISK")]
              ? profile[this.getConstant("DIMENSION_GROUP_RISK")][
                  this.getConstant("DIMENSION_ELEMENT_GENERAL_RISK")
                ]
              : [];
            _.forEach(surveys, (survey, surveyId) => {
              if (global && surveyId !== global.surveyEntityId) {
                var actualSurvey = _.find(this.interactions, i => {
                  return parseInt(i.surveyEntityId) === parseInt(surveyId);
                });
                if (_.includes(riskSurveysTypes, actualSurvey.surveyTypeId)) {
                  _.each(_.orderBy(survey, "startDate", "asc"), dim => {
                    this.security.risk.series[0].data.push(dim.dimensionValue);
                    var label = _.find(this.interactions, int => {
                      return (
                        parseInt(int.surveyEntityId) === parseInt(surveyId)
                      );
                    });
                    this.security.risk.labels.push(
                      label ? label.surveyName : ""
                    );
                    this.security.risk.dates.push(
                      label
                        ? moment(actualSurvey.dateStatus).format("ll")
                        : false
                    );
                  });
                }
              }
            });
            surveys = profile[this.getConstant("DIMENSION_GROUP_RISK")]
              ? profile[this.getConstant("DIMENSION_GROUP_RISK")][
                  this.getConstant("DIMENSION_ELEMENT_INTEGRITY_RISK")
                ]
              : [];
            _.forEach(surveys, (survey, surveyId) => {
              if (global && surveyId !== global.surveyEntityId) {
                var actualSurvey = _.find(this.interactions, i => {
                  return parseInt(i.surveyEntityId) === parseInt(surveyId);
                });
                if (_.includes(riskSurveysTypes, actualSurvey.surveyTypeId)) {
                  _.each(_.orderBy(survey, "startDate", "asc"), dim => {
                    this.security.risk.series[1].data.push(dim.dimensionValue);
                    var label = _.find(this.interactions, int => {
                      return (
                        parseInt(int.surveyEntityId) === parseInt(surveyId)
                      );
                    });
                    this.security.risk.labels.push(
                      label ? label.surveyName : ""
                    );
                    this.security.risk.dates.push(
                      label
                        ? moment(actualSurvey.dateStatus).format("ll")
                        : false
                    );
                  });
                }
              }
            });
            surveys = profile[this.getConstant("DIMENSION_GROUP_RISK")]
              ? profile[this.getConstant("DIMENSION_GROUP_RISK")][
                  this.getConstant("DIMENSION_ELEMENT_CONFIDENTIALITY_RISK")
                ]
              : [];
            _.forEach(surveys, (survey, surveyId) => {
              if (global && surveyId !== global.surveyEntityId) {
                var actualSurvey = _.find(this.interactions, i => {
                  return parseInt(i.surveyEntityId) === parseInt(surveyId);
                });
                if (_.includes(riskSurveysTypes, actualSurvey.surveyTypeId)) {
                  _.each(_.orderBy(survey, "startDate", "asc"), dim => {
                    this.security.risk.series[2].data.push(dim.dimensionValue);
                    var label = _.find(this.interactions, int => {
                      return (
                        parseInt(int.surveyEntityId) === parseInt(surveyId)
                      );
                    });
                    this.security.risk.labels.push(
                      label ? label.surveyName : ""
                    );
                    this.security.risk.dates.push(
                      label
                        ? moment(actualSurvey.dateStatus).format("ll")
                        : false
                    );
                  });
                }
              }
            });

            if (this.security.risk.series[0].data.length === 1) {
              this.security.risk.series[0].data.unshift(0);
              this.security.risk.series[1].data.unshift(0);
              this.security.risk.series[2].data.unshift(0);
              this.security.risk.labels.unshift("");
              this.security.risk.dates.unshift(null);
              this.security.risk.discrete = [
                {
                  seriesIndex: 0,
                  dataPointIndex: 0,
                  size: 0
                },
                {
                  seriesIndex: 0,
                  dataPointIndex: 2,
                  size: 0
                },
                {
                  seriesIndex: 1,
                  dataPointIndex: 0,
                  size: 0
                },
                {
                  seriesIndex: 1,
                  dataPointIndex: 2,
                  size: 0
                },
                {
                  seriesIndex: 2,
                  dataPointIndex: 0,
                  size: 0
                },
                {
                  seriesIndex: 2,
                  dataPointIndex: 2,
                  size: 0
                }
              ];
            }
            /* HAZARD */
            var hazSurveys = profile[this.getConstant("DIMENSION_GROUP_HAZARD")]
              ? profile[this.getConstant("DIMENSION_GROUP_HAZARD")][
                  this.getConstant("DIMENSION_ELEMENT_AVAILABILITY_HAZARD")
                ]
              : [];
            _.forEach(hazSurveys, (survey, surveyId) => {
              if (global && surveyId !== global.surveyEntityId) {
                var actualSurvey = _.find(this.interactions, i => {
                  return parseInt(i.surveyEntityId) === parseInt(surveyId);
                });
                if (_.includes(hazardSurveyTypes, actualSurvey.surveyTypeId)) {
                  _.each(_.orderBy(survey, "startDate", "asc"), dim => {
                    this.security.hazard.series[0].data.push(
                      dim.dimensionValue
                    );
                    var label = _.find(this.interactions, int => {
                      return (
                        parseInt(int.surveyEntityId) === parseInt(surveyId)
                      );
                    });
                    this.security.hazard.labels.push(
                      label ? label.surveyName : ""
                    );
                    this.security.hazard.dates.push(
                      label
                        ? moment(actualSurvey.dateStatus).format("ll")
                        : false
                    );
                  });
                }
              }
            });
            hazSurveys = profile[this.getConstant("DIMENSION_GROUP_HAZARD")]
              ? profile[this.getConstant("DIMENSION_GROUP_HAZARD")][
                  this.getConstant("DIMENSION_ELEMENT_INTEGRITY_HAZARD")
                ]
              : [];
            _.forEach(hazSurveys, (survey, surveyId) => {
              if (global && surveyId !== global.surveyEntityId) {
                var actualSurvey = _.find(this.interactions, i => {
                  return parseInt(i.surveyEntityId) === parseInt(surveyId);
                });
                if (_.includes(hazardSurveyTypes, actualSurvey.surveyTypeId)) {
                  _.each(_.orderBy(survey, "startDate", "asc"), dim => {
                    this.security.hazard.series[1].data.push(
                      dim.dimensionValue
                    );
                    var label = _.find(this.interactions, int => {
                      return (
                        parseInt(int.surveyEntityId) === parseInt(surveyId)
                      );
                    });
                    this.security.hazard.labels.push(
                      label ? label.surveyName : ""
                    );
                    this.security.hazard.dates.push(
                      label
                        ? moment(actualSurvey.dateStatus).format("ll")
                        : false
                    );
                  });
                }
              }
            });
            hazSurveys = profile[this.getConstant("DIMENSION_GROUP_HAZARD")]
              ? profile[this.getConstant("DIMENSION_GROUP_HAZARD")][
                  this.getConstant("DIMENSION_ELEMENT_CONFIDENTIALITY_HAZARD")
                ]
              : [];
            _.forEach(hazSurveys, (survey, surveyId) => {
              if (global && surveyId !== global.surveyEntityId) {
                var actualSurvey = _.find(this.interactions, i => {
                  return parseInt(i.surveyEntityId) === parseInt(surveyId);
                });
                if (_.includes(hazardSurveyTypes, actualSurvey.surveyTypeId)) {
                  _.each(_.orderBy(survey, "startDate", "asc"), dim => {
                    this.security.hazard.series[2].data.push(
                      dim.dimensionValue
                    );
                    var label = _.find(this.interactions, int => {
                      return (
                        parseInt(int.surveyEntityId) === parseInt(surveyId)
                      );
                    });
                    this.security.hazard.labels.push(
                      label ? label.surveyName : ""
                    );
                    this.security.hazard.dates.push(
                      label
                        ? moment(actualSurvey.dateStatus).format("ll")
                        : false
                    );
                  });
                }
              }
            });
          }
          if (this.security.hazard.series[0].data.length === 1) {
            this.security.hazard.series[0].data.unshift(0);
            this.security.hazard.series[1].data.unshift(0);
            this.security.hazard.series[2].data.unshift(0);
            this.security.hazard.labels.unshift("");
            this.security.hazard.dates.unshift(null);
            this.security.hazard.discrete = [
              {
                seriesIndex: 0,
                dataPointIndex: 0,
                size: 0
              },
              {
                seriesIndex: 0,
                dataPointIndex: 2,
                size: 0
              },
              {
                seriesIndex: 1,
                dataPointIndex: 0,
                size: 0
              },
              {
                seriesIndex: 1,
                dataPointIndex: 2,
                size: 0
              },
              {
                seriesIndex: 2,
                dataPointIndex: 0,
                size: 0
              },
              {
                seriesIndex: 2,
                dataPointIndex: 2,
                size: 0
              },
              {
                seriesIndex: 3,
                dataPointIndex: 0,
                size: 0
              },
              {
                seriesIndex: 3,
                dataPointIndex: 2,
                size: 0
              }
            ];
          }
          /* INSIDERISM */
          var dimensions = [
            "COMMITMENT",
            "TRUST",
            "CHALLENGE",
            "PRAGMATISM",
            "DISSATISFACTION",
            "NEGLIGENCE",
            "EXPEDITION",
            "OVERLOAD",
            "DIVERGENCE"
          ];
          var statsArray = [];
          dimensions.forEach(dimension => {
            var dimensionId = this.getConstant(
              `DIMENSION_ELEMENT_${dimension}`
            );
            var value = extractValueFromProfile(
              securityProfileResp.data.records,
              "DIMENSION_GROUP_INSIDER",
              "SURVEY_KYMATIO_GRI_GLOBAL",
              "dimensionValue",
              this.interactions,
              `DIMENSION_ELEMENT_${dimension}`
            );
            if (value) {
              statsArray.push({
                id: dimensionId,
                name: this.$t(
                  `tables.dimensionIds.dimension.${dimensionId}.DEFAULT.title`
                ),
                shortName: this.$t(
                  `tables.dimensionIds.dimension.${dimensionId}.DEFAULT.title`
                ),
                value: value || 0
              });
            }
          });
          this.insiderRadar = statsArray;
          this.loaded = true;
        })
      );
  }
};
</script>
