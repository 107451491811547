var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{staticClass:"card card-custom card-stretch gutter-b",attrs:{"no-body":""}},[_c('div',{staticClass:"card-header border-0"},[_c('h3',{staticClass:"card-title font-weight-bolder text-dark"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.sessions.title"))+" ")])]),_c('div',{staticClass:"card-body pt-5"},[_c('div',{staticClass:"timeline timeline-5"},[_c('div',{staticClass:"timeline-items"},_vm._l((_vm.formatedSessions),function(session){return _c('div',{key:session.id,staticClass:"timeline-item mt-6 text-left",class:{ 'no-line': _vm.isWelcome(session.family) },style:({ cursor: 'pointer' }),on:{"click":function($event){return _vm.goToSession(session)}}},[_c('div',{class:`timeline-media bg-light-${_vm.getStatusClass(session)}`},[_c('span',{class:`svg-icon svg-icon-2x svg-icon-${_vm.getStatusClass(session)}`},[_c('simple-svg',{attrs:{"src":require(`@/assets/images/icons/${_vm.getIconByType(
                    session.family
                  )}`)}})],1)]),_c('div',{class:`timeline-desc timeline-desc-light-${_vm.getStatusClass(session)}`},[_c('span',{staticClass:"font-weight-bolder d-flex align-items-center justify-content-between"},[(
                  !_vm.controls ||
                    parseInt(session.family) ===
                      parseInt(_vm.getConstant('SURVEYTYPE_KYMATIO_WELCOME'))
                )?_c('span',{class:`text-dark-75`,domProps:{"innerHTML":_vm._s(session.name)}}):_c('span',{class:`text-dark-75`,domProps:{"innerHTML":_vm._s(session.name)}}),(
                  _vm.controls &&
                    session.status !== 'unavailable' &&
                    !(
                      parseInt(session.family) ===
                        parseInt(_vm.getConstant('SURVEYTYPE_KYMATIO_WELCOME')) &&
                      session.status === 'finish'
                    )
                )?_c('b-dropdown',{staticClass:"dropdown dropdown-inline ml-2",attrs:{"toggle-class":"btn-icon","variant":"clean","menu-class":"dropdown-menu p-0 m-0","size":"sm","offset":"10","right":"","no-caret":true}},[_c('template',{slot:"button-content"},[_c('i',{staticClass:"ki ki-bold-more-hor"})]),_c('template',{slot:"default"},[_c('ul',{staticClass:"navi navi-hover"},[(session.status === 'available')?_c('li',{staticClass:"navi-item"},[_c('a',{staticClass:"navi-link",on:{"click":function($event){return _vm.goToSession(session)}}},[_c('span',{staticClass:"navi-icon"},[_c('i',{staticClass:"flaticon2-next"})]),_c('span',{staticClass:"navi-text"},[_vm._v(_vm._s(_vm.$t("dashboard.sessions.actions.start")))])])]):_vm._e(),(session.status === 'progress')?_c('li',{staticClass:"navi-item"},[_c('a',{staticClass:"navi-link",on:{"click":function($event){return _vm.goToSession(session)}}},[_c('span',{staticClass:"navi-icon"},[_c('i',{staticClass:"flaticon2-fast-next"})]),_c('span',{staticClass:"navi-text"},[_vm._v(_vm._s(_vm.$t("dashboard.sessions.actions.continue")))])])]):_vm._e(),(session.status === 'finish')?_c('li',{staticClass:"navi-item"},[_c('router-link',{staticClass:"navi-link",attrs:{"to":{
                          name: 'TrainingDetail',
                          params: { id: session.surveyEntityId }
                        }}},[_c('span',{staticClass:"navi-icon"},[_c('i',{staticClass:"flaticon2-graphic-1"})]),_c('span',{staticClass:"navi-text"},[_vm._v(_vm._s(_vm.$t("dashboard.sessions.actions.report")))])])],1):_vm._e()])])],2):_vm._e()],1),_c('div',{staticClass:"font-weight-bolder d-flex align-items-top justify-content-between pb-1"},[_c('span',{class:`label label-lg label-outline-${_vm.getStatusClass(
                    session
                  )} font-weight-bolder label-inline ml-2`},[_vm._v(" "+_vm._s(_vm.$t(`dashboard.sessions.status.${session.status}`))+" ")]),_c('p',{staticClass:"font-weight-normal text-dark-50",domProps:{"innerHTML":_vm._s(session.calendar)}})])])])}),0)])]),(_vm.hasPager)?_c('div',{staticClass:"card-footer border-top-0 pt-0"},[_c('div',{staticClass:"d-flex justify-content-around"},[_c('div',{staticClass:"d-flex flex-column"},[_c('b-button',{staticClass:"btn-icon",attrs:{"pill":"","variant":_vm.canGoUp ? 'light-primary' : 'disabled',"disabled":!_vm.canGoUp},on:{"click":_vm.up}},[_c('i',{staticClass:"fa fa-chevron-up"})])],1),_c('div',{staticClass:"d-flex flex-column"},[_c('b-button',{staticClass:"btn-icon",attrs:{"pill":"","variant":_vm.canGoDown ? 'light-primary' : 'disabled',"disabled":!_vm.canGoDown},on:{"click":_vm.down}},[_c('i',{staticClass:"fa fa-chevron-down"})])],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }