var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('b-row',[(
      _vm.security.risk.labels.length > 0 || _vm.security.hazard.labels.length > 0
    )?_c('b-col',{attrs:{"col":"","xl":"4","lg":"3","md":"12","sm":"12","xs":"12"}},[(_vm.hasModule(['risk']) && _vm.security.risk.labels.length > 0)?_c('chart',{attrs:{"title":_vm.$t('tables.detail.user.evolution', {
          module: _vm.$t('tables.risk')
        }),"series":_vm.security.risk.series,"categories":_vm.security.risk.labels,"dates":_vm.security.risk.dates,"discrete":_vm.security.risk.discrete,"base":6,"half":_vm.hasModule(['risk']) &&
          _vm.security.risk.labels.length > 0 &&
          _vm.hasModule(['impact']) &&
          _vm.security.hazard.labels.length > 0,"opacity":true,"colors":{
        light: ['#8558409b', '#e6b3759b', '#beb09f9b'],
        normal: ['#855840', '#e6b375', '#beb09f']
      },"height":220}}):_vm._e(),(_vm.hasModule(['impact']) && _vm.security.hazard.labels.length > 0)?_c('chart',{attrs:{"title":_vm.$t('tables.detail.user.evolution', {
          module: _vm.$t('tables.hazard')
        }),"series":_vm.security.hazard.series,"categories":_vm.security.hazard.labels,"dates":_vm.security.hazard.dates,"discrete":_vm.security.hazard.discrete,"base":3,"half":_vm.hasModule(['risk']) &&
          _vm.security.risk.labels.length > 0 &&
          _vm.hasModule(['impact']) &&
          _vm.security.hazard.labels.length > 0,"opacity":true,"colors":{
        light: ['#8558409b', '#e6b3759b', '#beb09f9b'],
        normal: ['#855840', '#e6b375', '#beb09f']
      },"height":220}}):_vm._e()],1):_vm._e(),(_vm.actions.length > 0 && _vm.hasModule(['risk']))?_c('b-col',{attrs:{"col":"","cols":"auto","xl":"8","lg":"9","md":"12","sm":"12","xs":"12"}},[_c('tabs',{attrs:{"tabs":_vm.actions}})],1):_c('b-col',[_c('empty-card',{attrs:{"type":"user.security"}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }