var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('b-row',[(_vm.cyber.series.length > 0)?_c('b-col',{staticClass:"mt-1",attrs:{"col":"","xl":"4","lg":"3","md":"12","sm":"12","xs":"12"}},[_c('chart',{attrs:{"title":_vm.$t('tables.detail.user.awarenessEvolution'),"series":[
        {
          name: _vm.$t('generic.modules.cybersecurity.title'),
          data: _vm.cyber.series
        }
      ],"categories":_vm.cyber.labels,"dates":_vm.cyber.dates,"discrete":_vm.cyber.discrete,"colors":{
        light: ['#e0c3d5'],
        normal: ['#ce93b8']
      },"height":365}})],1):_vm._e(),(_vm.cyber.series.length > 0)?_c('b-col',{staticClass:"mt-1",attrs:{"col":"","cols":"auto","xl":"8"}},[_c('heat-map',{attrs:{"series":_vm.cyber.heatmap.series,"title":_vm.$t('tables.detail.user.heatmap'),"dimensions":_vm.dimensions}})],1):_vm._e(),(_vm.cyber.series.length === 0)?_c('b-col',[_c('empty-card',{attrs:{"type":"user.cybersecurity"}})],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }