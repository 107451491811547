<template>
  <b-card
    class="card-custom card-stretch gutter-b"
    body-class="p-0 d-flex flex-column"
    header-class="border-0 pt-5"
  >
    <template #header>
      <b-card-title class="font-weight-bolder">
        <div class="card-label">{{ title }}</div>
      </b-card-title>
    </template>
    <div v-if="Object.keys(data).length > 0" class="p-0 m-0">
      <apexchart
        class="card-rounded-bottom"
        type="radar"
        :height="height"
        :options="options"
        :series="series"
      ></apexchart>
    </div>
  </b-card>
  <!--end::Body-->
</template>

<script>
import _ from "lodash";

export default {
  name: "CyberChart",
  props: {
    data: {
      required: true,
      type: Array
    },
    title: {
      required: true,
      type: String
    },
    height: {
      type: String,
      default: "350"
    },
    colors: {
      type: String,
      default: "#d6bfa4"
    }
  },
  computed: {
    options() {
      var self = this;
      return {
        chart: {
          height: self.height,
          type: "radar",
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false,
          formatter: value => self.$n(value / 10, "percent")
        },
        plotOptions: {
          radar: {
            size: 100,
            polygons: {
              strokeColors: "#f5f5f5",
              strokeWidth: 1,
              connectorColors: "#f0f0f0",
              fill: {
                colors: ["transparent"]
              }
            }
          }
        },
        colors: [this.colors],
        markers: {
          size: 4,
          colors: ["#fff"],
          strokeColor: this.colors,
          strokeWidth: 2
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: value => self.$n(value / 10, "percent"),
            title: {
              formatter: () => ""
            }
          }
        },
        grid: {
          show: false
        },
        xaxis: {
          categories: _.map(this.data, e => e.label),
          labels: {
            style: {
              fontSize: "13px",
              fontFamily: "Raleway, Helvetica, sans-serif"
            }
          }
        },
        yaxis: {
          max: 10,
          min: 0,
          show: false
        }
      };
    },
    series() {
      return [
        {
          name: this.$t("modules.insider.title"),
          data: _.map(this.data, e => e.value / 10 || 0)
        }
      ];
    }
  },
  methods: {}
};
</script>

<style lang="scss">
.apexcharts-legend.position-left {
  text-align: left;
}
.apexcharts-datalabel {
  color: #464e5f;
}
</style>
