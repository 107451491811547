var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{class:'card-custom card-stretch gutter-b ' + (_vm.half ? 'card-stretch-half' : ''),attrs:{"no-body":""}},[_c('div',{staticClass:"card-header border-0"},[_c('h3',{staticClass:"card-title font-weight-bolder text-dark"},[_vm._v(" "+_vm._s(_vm.$tc("dashboard.alerts.title", _vm.total, { total: _vm.total }))+" ")])]),_c('div',{staticClass:"card-body pt-0"},[_vm._l((_vm.alerts),function(alert){return _c('div',{key:alert.type + alert.level,class:`d-flex align-items-center pb-3 is-link text-hover-${alert.level}`,on:{"click":function($event){return _vm.goToDetail(alert)}}},[_c('div',{staticClass:"d-flex align-items-center flex-grow-1"},[_c('div',{class:`symbol symbol-45 symbol-white mr-4 flex-shrink-0`},[_c('div',{staticClass:"symbol-label"},[_c('span',{class:`svg-icon svg-icon-2x`},[_c('simple-svg',{attrs:{"src":require(`@/assets/images/icons/${alert.icon}`)}})],1),_c('span',{class:`has-badge font-size-xs label label-pill font-weight-bolder label-${alert.level}`},[_vm._v(" "+_vm._s(alert.total)+" ")])])]),_c('div',{staticClass:"d-flex flex-wrap align-items-center justify-content-between w-100 text-left"},[_c('div',{staticClass:"d-flex flex-column align-items-cente py-2 w-auto"},[_c('span',{class:`text-dark-75 font-weight-bold font-size-lg mb-1`,domProps:{"innerHTML":_vm._s(
                _vm.$tc(
                  `dashboard.alerts.${alert.type}.${_vm.type}.${alert.level}`,
                  alert.counter !== undefined ? alert.counter : alert.total,
                  { total: alert.total }
                )
              )}})])])]),(alert.to)?_c('div',{staticClass:"ml-2"},[_c('b-button',{class:`bg-transparent border-0 svg-icon svg-icon-2x`,attrs:{"tag":"div"},on:{"click":function($event){alert.chat
              ? _vm.activate(alert.chat)
              : alert.to
              ? _vm.$router.push(alert.to)
              : ''}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('g',{attrs:{"fill":"none","fill-rule":"evenodd"}},[_c('path',{attrs:{"d":"M0 0h24v24H0z"}}),_c('path',{attrs:{"d":"M12.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 01-1.474-1.352l4.853-5.294-5.323-5.323z","fill":"#000","fill-rule":"nonzero"}}),_c('path',{attrs:{"d":"M5.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 11-1.474-1.352l4.853-5.294-5.323-5.323z","fill":"#000","fill-rule":"nonzero","opacity":".3"}})])])])],1):_vm._e()])}),(_vm.hasModule(['gaming_achievements']))?_c('achievements-alerts',{attrs:{"link":_vm.link,"achievements":_vm.achievements}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }