<template>
  <div id="user-detail">
    <div class="subheader subheader-transparent mb-6" id="kt_subheader">
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap p-0"
      >
        <!--begin::Info-->
        <div class="d-flex align-items-center flex-wrap mr-1">
          <!--begin::Page Heading-->
          <div class="d-flex align-items-baseline flex-wrap mr-5">
            <!--begin::Page Title-->
            <h5 class="text-dark font-weight-bold my-1 mr-5">
              {{ $tc("tables.user") }}
            </h5>
            <!--end::Page Title-->
          </div>
          <!--end::Page Heading-->
        </div>
        <div class="d-flex align-items-center">
          <b-button
            tag="a"
            @click="
              hasHistory ? $router.go(-1) : $router.push({ name: 'Users' })
            "
            size="sm"
            pill
            variant="secondary"
            class="font-weight-bolder text-uppercase mr-2"
          >
            <div class="d-flex align-items-center">
              <i class="fa fa-chevron-left" />
              <span style=" white-space: nowrap">
                {{ $t("generic.buttons.back") }}
              </span>
            </div>
          </b-button>
        </div>
        <!--end::Info-->
      </div>
    </div>
    <b-card class="card-custom">
      <!--begin::Top-->
      <div class="d-flex">
        <!--begin::Pic-->
        <div class="flex-shrink-0 mr-7">
          <div class="symbol symbol-50 symbol-lg-120">
            <avatar
              :showArchetype="false"
              :avatar="user.avatar || '000-Empty.svg'"
            />
          </div>
        </div>
        <!--end::Pic-->
        <!--begin: Info-->
        <div class="flex-grow-1">
          <!--begin::Title-->
          <div
            class="d-flex align-items-center justify-content-between flex-wrap mt-2"
          >
            <!--begin::User-->
            <div class="mr-3">
              <!--begin::Name-->
              <div
                class="d-flex align-items-center text-dark font-size-h5 font-weight-bold mr-3"
              >
                {{ user.name }}
                <i
                  v-if="user.isBoss"
                  class="flaticon2-correct text-success icon-md ml-2"
                ></i>
              </div>
              <!--end::Name-->
              <!--begin::Contacts-->
              <div class="d-flex flex-wrap my-2">
                <span
                  class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                >
                  {{ user.department }}
                </span>
              </div>
              <div class="d-flex flex-wrap my-2">
                <span
                  class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                >
                  <span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M21 12.083A6 6 0 0014.083 19H5a2 2 0 01-2-2V8a2 2 0 012-2h14a2 2 0 012 2v4.083zM18.144 7.84L12 11.148 5.856 7.84a.75.75 0 10-.712 1.32l6.5 3.5c.222.12.49.12.712 0l6.5-3.5a.75.75 0 00-.712-1.32z" fill="#000"/><circle fill="#000" opacity=".3" cx="19.5" cy="17.5" r="2.5"/></g></svg>
                  </span>
                  {{ user.email }}
                </span>
              </div>
              <!--end::Contacts-->
            </div>
            <!--begin::User-->
          </div>
          <!--end::Title-->
          <!--begin::Content-->
          <div
            class="d-flex align-items-center flex-wrap justify-content-between"
          >
            <!--begin::Progress-->
            <div
              class="d-flex mt-4 mt-sm-0 py-2 py-lg-2"
              v-if="hasModule(['risk'])"
            >
              <span class="font-weight-bold mr-4">
                {{ $t("tables.progress") }}
              </span>
              <b-progress
                class="progress-xs mt-2 mb-2 flex-shrink-0 w-150px w-xl-250px"
              >
                <b-progress-bar
                  :max="10"
                  :animated="false"
                  :value="user.progress !== false ? user.progress : '-'"
                  :style="
                    `background-color: ${getHexByValue(user.progress, 10)}`
                  "
                  :striped="false"
                />
              </b-progress>
              <span class="font-weight-bolder text-dark ml-4">{{
                $n(user.progress / 10, "percent")
              }}</span>
            </div>
            <!--end::Progress-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Info-->
      </div>
      <!--end::Top-->
      <!--begin::Separator-->
      <div class="separator separator-solid my-7"></div>
      <!--end::Separator-->
      <!--begin::Bottom-->
      <div class="d-flex align-items-center flex-wrap">
        <!--begin: Item-->
        <div
          class="d-flex align-items-center flex-lg-fill mr-5 my-1 justify-content-center"
          v-if="hasModule(['risk'])"
        >
          <div class="d-flex flex-column text-dark-75">
            <span class="font-weight-bolder font-size-md pb-2">{{
              $t("tables.insider")
            }}</span>
            <b-badge class="base-full p-2" pill style="font-size: 1rem;">
              {{
                user.insider
                  ? $t(
                      `tables.dimensionIds.dimension.${user.insider}.DEFAULT.title`
                    )
                  : "-"
              }}
            </b-badge>
          </div>
        </div>
        <!--end: Item-->
        <!--begin: Item-->
        <div
          class="d-flex align-items-center flex-lg-fill mr-5 my-1 justify-content-center"
          v-if="hasModule(['risk'])"
        >
          <span class="mr-4">
            <i
              :class="
                'fas fa-chart-pie icon-2x ' +
                  variantByValue(user.risk, 6, true, false)
              "
            ></i>
          </span>
          <div class="d-flex flex-column text-dark-75">
            <span class="font-weight-bolder font-size-md">{{
              $t("tables.risk")
            }}</span>
            <span class="font-weight-bolder font-size-h5">
              {{ user.risk !== false ? user.risk : "-" }}
              <span class="font-size-sm">/ 6</span>
            </span>
          </div>
        </div>
        <!--end: Item-->
        <!--begin: Item-->
        <div
          class="d-flex align-items-center flex-lg-fill mr-5 my-1 justify-content-center"
          v-if="hasModule(['impact'])"
        >
          <span class="mr-4">
            <i
              :class="
                'fas fa-radiation-alt icon-2x ' +
                  variantByValue(user.hazard, 3, true, false)
              "
            ></i>
          </span>
          <div class="d-flex flex-column text-dark-75">
            <span class="font-weight-bolder font-size-md">
              {{ $t("tables.hazard") }}
            </span>
            <span class="font-weight-bolder font-size-h5">
              {{ user.hazard !== false ? $n(user.hazard) : "-" }}
              <span class="font-size-sm">/ 3</span>
            </span>
          </div>
        </div>
        <!--end: Item-->
        <!--begin: Item-->
        <div
          class="d-flex align-items-center flex-lg-fill mr-5 my-1 justify-content-center"
          v-if="hasModule(['awareness'])"
        >
          <span class="mr-4">
            <i
              :class="
                'fas fa-shield-virus icon-2x ' +
                  variantByValue(user.cyber, 10, true, false)
              "
            ></i>
          </span>
          <div class="d-flex flex-column text-dark-75">
            <span class="font-weight-bolder font-size-md">
              {{ $t("tables.cyber") }}
            </span>
            <span class="font-weight-bolder font-size-h5">
              {{ user.cyber !== false ? $n(user.cyber) : "-" }}
              <span class="font-size-sm">/ 10</span>
            </span>
          </div>
        </div>
        <!--end: Item-->
        <!--begin: Item-->
        <div
          class="d-flex align-items-center flex-lg-fill mr-5 my-1 justify-content-center"
          v-if="hasModule(['wellbeing'])"
        >
          <span class="mr-4">
            <i
              :class="
                'fas fa-thermometer-quarter icon-2x ' +
                  variantByValue(user.wellbeing, 10, true, false)
              "
            ></i>
          </span>
          <div class="d-flex flex-column text-dark-75">
            <span class="font-weight-bolder font-size-md">
              {{ $t("tables.wellbeing") }}
            </span>
            <span class="font-weight-bolder font-size-h5">
              {{ user.wellbeing !== false ? $n(user.wellbeing) : "-" }}
              <span class="font-size-sm">/ 10</span>
            </span>
          </div>
        </div>
        <!--end: Item-->
        <!--begin: Item-->
        <div
          class="d-flex align-items-center flex-lg-fill mr-5 my-1"
          v-if="user.liar"
        >
          <span class="mr-4">
            <i
              :class="
                'fas fa-glasses icon-2x ' +
                  variantByValue(user.liar, 10, true, false)
              "
            ></i>
          </span>
          <div class="d-flex flex-column text-dark-75">
            <span class="font-weight-bolder font-size-md">
              {{ $t("tables.liar") }}
            </span>
          </div>
        </div>
        <!--end: Item-->
        <!--begin: Item-->
        <div
          class="d-flex align-items-center flex-lg-fill mr-5 my-1"
          v-if="user.desirability"
        >
          <span class="mr-4">
            <i
              :class="
                'fas fa-theater-masks icon-2x ' +
                  variantByValue(user.desirability, 10, true, false)
              "
            ></i>
          </span>
          <div class="d-flex flex-column text-dark-75">
            <span class="font-weight-bolder font-size-md">
              {{ $t("tables.desirability") }}
            </span>
          </div>
        </div>
        <!--end: Item-->
      </div>
      <!--end::Bottom-->
    </b-card>
    <box-tabs :tabs="tabs" @changeTab="changeModule" />
    <awareness
      id="awareness-module"
      :stakeholderId="this.$route.params.id"
      :interactions="interactions"
      v-if="selected === 'awareness'"
    />
    <security
      id="security-module"
      :stakeholderId="this.$route.params.id"
      :interactions="interactions"
      v-if="selected === 'security'"
    />
    <wellbeing
      id="wellbeing-module"
      :stakeholderId="this.$route.params.id"
      :interactions="interactions"
      v-if="selected === 'wellbeing'"
    />
    <archetype
      id="archetype-module"
      :stakeholderId="this.$route.params.id"
      v-if="selected === 'archetype'"
    />
    <social-engineering
      id="social-module"
      :stakeholderId="this.$route.params.id"
      :interactions="interactions"
      v-if="selected === 'social'"
    />
    <phishing
      id="phishing-module"
      :stakeholderId="$route.params.id"
      :user="user"
      v-if="selected === 'phishing'"
    />
    <insider
      id="insider-module"
      :stakeholderId="$route.params.id"
      :interactions="interactions"
      v-if="selected === 'insider'"
    />
    <burnout
      id="burnout-module"
      :stakeholderId="$route.params.id"
      :interactions="interactions"
      v-if="selected === 'burnout'"
    />

    <b-row align-h="center" v-if="selected === 'abs'">
      <b-col lg="3" sm="12">
        <breaches-progress-pwd
          v-if="user.abs.global"
          :data="user.abs"
          type="user"
        />
        <breaches-progress-no-pwd
          v-if="user.abs.global"
          :data="user.abs"
          type="user"
        />
      </b-col>
      <b-col lg="9" sm="12">
        <user-breaches-list
          id="user-breaches-module"
          :stakeholderId="$route.params.id"
          :breaches="breaches"
        />
      </b-col>
    </b-row>
    <b-row align-h="center" v-if="selected === 'timeline'">
      <b-col lg="6">
        <sessions-timeline
          id="timeline-module"
          class="mt-1"
          :sessions="filteredSessions"
          :limit="5"
          :controls="false"
        />
      </b-col>
      <b-col lg="6">
        <alerts :data="alerts" :link="false" type="controller" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { getStakeholder } from "@/api/controller/stakeholders.api";
import {
  getInteractions,
  getStakeholderAssets,
  getProfile
} from "@/api/controller/traits.api";
import {
  extractValueFromProfile,
  getClassByValueAndBase,
  getColorByValueAndBase
} from "@/utils/helpers";

import _ from "lodash";
import axios from "axios";
import { mapGetters } from "vuex";
import VueScrollTo from "vue-scrollto";
import moment from "moment";

import Alerts from "@/components/dashboard/Alerts.component";
import BreachesProgressPwd from "@/components/dashboard/breaches-progress/BreachesProgressPwd.component";
import BreachesProgressNoPwd from "@/components/dashboard/breaches-progress/BreachesProgressNoPwd.component";
import Avatar from "@/components/profile/Avatar.component";
import Security from "@/components/users/Security.component";
import Awareness from "@/components/users/Awareness.component";
import SocialEngineering from "@/components/users/SocialEngineering.component";
import Wellbeing from "@/components/users/Wellbeing.component";
import Archetype from "@/components/users/Archetype.component";
import Phishing from "@/components/users/Phishing.component";
import Insider from "@/components/users/Insider.component";
import Burnout from "@/components/users/Burnout.component";
import SessionsTimeline from "@/components/dashboard/SessionsTimeline.component";
import BoxTabs from "@/components/menu/BoxTabs.component";
import UserBreachesList from "@/components/breaches/UserBreachesList.component";
import { getStakeholderBreaches } from "@/api/controller/breaches.api";

export default {
  name: "UserDetail",
  data() {
    return {
      user: {},
      selected: "",
      interactions: [],
      sessions: [],
      breaches: []
    };
  },
  components: {
    Alerts,
    BreachesProgressPwd,
    BreachesProgressNoPwd,
    Avatar,
    Awareness,
    Wellbeing,
    Archetype,
    Security,
    SocialEngineering,
    Phishing,
    Insider,
    SessionsTimeline,
    BoxTabs,
    UserBreachesList,
    Burnout
  },
  computed: {
    ...mapGetters("Constants", ["isConstantsActive", "getConstant"]),
    ...mapGetters("Auth", ["hasModule"]),
    radarData() {
      return [
        {
          label: this.$t("tables.risk"),
          value: 100 - (this.user.risk * 50) / 3
        },
        {
          label: this.$t("tables.hazard"),
          value: 100 - (this.user.hazard * 100) / 3
        },
        {
          label: this.$t("tables.cyber"),
          value: this.user.cyber * 10
        },
        {
          label: this.$t("tables.wellbeing"),
          value: this.user.wellbeing * 10
        }
      ];
    },
    hasHistory() {
      return window.history.length > 2;
    },
    filteredSessions() {
      return _.orderBy(
        _.filter(
          this.sessions,
          session =>
            parseInt(session.familyTypeId) !==
              parseInt(this.getConstant("SURVEYTYPE_KYMATIO_PHISHING")) &&
            parseInt(session.familyTypeId) !==
              parseInt(this.getConstant("SURVEYTYPE_KYMATIO_ABS"))
        ),
        [
          e => {
            let val = 100;
            switch (_.toLower(e.surveyStatus)) {
              case "available":
              case "unavailable":
                val = 0;
                break;
              case "progress":
                val = 50;
                break;
            }
            return val;
          },
          e => {
            var thedate = moment(e.dateStatus).format("x");
            return thedate;
          }
        ],
        ["asc", "desc"]
      );
    },
    tabs() {
      var result = [
        {
          id: "timeline",
          image: "clock.svg",
          title: this.$t("generic.modules.timeline.title")
        },
        {
          id: "awareness",
          image: "shield-protected.svg",
          title: this.$t("generic.modules.awareness.title"),
          service: ["awareness"]
        },
        {
          id: "wellbeing",
          image: "home-heart.svg",
          title: this.$t("generic.modules.wellbeing.title"),
          service: ["wellbeing"]
        },
        {
          id: "archetype",
          image: "street-view-solid.svg",
          title: this.$t("generic.modules.archetype.title"),
          service: ["archetype"]
        },
        {
          id: "security",
          image: "mail-locked.svg",
          title: this.$t("generic.modules.security.title"),
          service: ["risk", "impact"]
        },
        {
          id: "insider",
          image: "diagnostics.svg",
          title: this.$t("generic.modules.insider.title"),
          service: ["risk"]
        },
        {
          id: "social",
          image: "fingerprint.svg",
          title: this.$t("generic.modules.social.title"),
          service: ["socialengineering"]
        },
        {
          id: "phishing",
          image: "fish.svg",
          title: this.$t("generic.modules.phishing.title"),
          service: ["phishing"]
        },
        {
          id: "abs",
          image: "key.svg",
          title: this.$t("generic.modules.abs.title"),
          service: ["abs"]
        },
        {
          id: "burnout",
          image: "fire.svg",
          title: this.$t("generic.modules.burnout.title"),
          service: ["burnout"]
        }
      ];
      return _.filter(result, item => {
        return item.service === undefined || this.hasModule(item.service);
      });
    },
    alerts() {
      var result = [];
      if (this.user.pendingInteractions.length > 0) {
        var oldest = _.countBy(
          this.user.pendingInteractions,
          interaction =>
            moment().diff(moment(interaction.userStartDate), "months") >= 1
        );
        if (oldest && oldest.true > 0) {
          result.push({
            type: "interaction",
            level: "danger",
            levelNo: 1,
            total: oldest.true,
            icon: "shield-disabled.svg"
          });
        }
        if (oldest && oldest.false > 0) {
          result.push({
            type: "interaction",
            level: "warning",
            levelNo: 2,
            total: oldest.false,
            icon: "warning-triangle.svg"
          });
        }
      } else {
        if (oldest && oldest.true > 0) {
          result.push({
            type: "interaction",
            level: "success",
            levelNo: 3,
            total: 0,
            icon: "double-check.svg"
          });
        }
      }

      var unsolved = this.user.abs
        ? this.user.abs.totalbreaches - this.user.abs.totalbreachessolved
        : 0;

      var passwordUnsolved = this.user.abs
        ? this.user.abs.totalbreachespwd - this.user.abs.totalbreachespwdsolved
        : 0;

      if (this.hasModule(["abs"])) {
        if (unsolved > 0 && passwordUnsolved > 0) {
          result.push({
            type: "breach",
            level: "danger",
            levelNo: 1,
            total: passwordUnsolved,
            icon: "shield-disabled.svg"
          });
        }
        if (
          unsolved > 0 &&
          parseInt(unsolved) - parseInt(passwordUnsolved) > 0
        ) {
          result.push({
            type: "breach",
            level: "warning",
            levelNo: 2,
            total: parseInt(unsolved) - parseInt(passwordUnsolved),
            icon: "warning-triangle.svg"
          });
        }
        if (
          this.user.abs &&
          this.user.abs.totalbreaches === this.user.abs.totalbreachessolved
        ) {
          if (this.user.abs.totalbreaches === 0) {
            result.push({
              type: "breach",
              level: "success",
              levelNo: 3,
              total: 0,
              counter: 1,
              icon: "double-check.svg"
            });
          } else {
            result.push({
              type: "breach",
              level: "success",
              levelNo: 3,
              total: 0,
              counter: 2,
              icon: "double-check.svg"
            });
          }
        }
        if (!this.user.abs) {
          result.push({
            type: "breach",
            level: "success",
            levelNo: 3,
            total: 0,
            counter: 1,
            icon: "double-check.svg"
          });
        }
      }

      var assetsPending = _.countBy(this.user.assets, asset =>
        parseInt(asset.totalReaded) === 0 ? asset.assetCount : false
      );
      if (assetsPending["2"] > 0) {
        result.push({
          type: "assets",
          level: "danger",
          levelNo: 1,
          total: assetsPending["2"],
          icon: "shield-disabled.svg"
        });
      }
      if (assetsPending["1"] > 0) {
        result.push({
          type: "assets",
          level: "warning",
          levelNo: 2,
          total: assetsPending["1"],
          icon: "warning-triangle.svg"
        });
      }
      if (assetsPending["2"] === 0 && assetsPending["1"] === 0) {
        result.push({
          type: "assets",
          level: "success",
          levelNo: 3,
          total: 0,
          icon: "double-check.svg"
        });
      }

      return result;
    }
  },
  methods: {
    variantByValue(value, base = 100, color = true, bg = true) {
      return getClassByValueAndBase(value, base, color, bg);
    },
    changeModule(mod) {
      this.selected = mod;
      this.$router.push(
        _.merge(this.$route.params, { hash: `#${this.selected}` })
      );
      VueScrollTo.scrollTo(`.card-header.card-header-tabs-line`, 1500);
      return mod;
    },
    getHexByValue(value, base) {
      return getColorByValueAndBase(value, base);
    },
    loadUser() {
      var profileBasicParams = {
        surveyTypes: [
          this.getConstant("SURVEY_KYMATIO_GRI_GLOBAL"),
          this.getConstant("SURVEY_KYMATIO_CLIMATE_GLOBAL"),
          this.getConstant("SURVEY_KYMATIO_CYBERSECURITY_GLOBAL"),
          this.getConstant("SURVEY_KYMATIO_SCORE_GLOBAL"),
          this.getConstant("SURVEY_KYMATIO_BREACH_CORPORATE")
        ],
        dimensions: [
          this.getConstant("DIMENSION_ELEMENT_KYMATIO_GLOBAL_NOTE"),
          this.getConstant("DIMENSION_ELEMENT_CLIMATE_LEVEL"),
          this.getConstant("DIMENSION_ELEMENT_CONFIDENTIALITY_RISK"),
          this.getConstant("DIMENSION_ELEMENT_AVAILABILITY_RISK"),
          this.getConstant("DIMENSION_ELEMENT_INTEGRITY_RISK"),
          this.getConstant("DIMENSION_ELEMENT_GENERAL_RISK"),
          this.getConstant("DIMENSION_ELEMENT_CONFIDENTIALITY_HAZARD"),
          this.getConstant("DIMENSION_ELEMENT_AVAILABILITY_HAZARD"),
          this.getConstant("DIMENSION_ELEMENT_INTEGRITY_HAZARD"),
          this.getConstant("DIMENSION_ELEMENT_GENERAL_HAZARD"),
          this.getConstant("DIMENSION_ELEMENT_PRINCIPAL_INSIDER"),
          this.getConstant("DIMENSION_ELEMENT_CIBER_RESULTS"),
          this.getConstant("DIMENSION_ELEMENT_DESIRABILITY"),
          this.getConstant("DIMENSION_ELEMENT_LIAR"),
          this.getConstant("DIMENSION_ELEMENT_AGGREGATED_RISK"),
          this.getConstant("DIMENSION_ELEMENT_AVERAGE_RISK"),
          this.getConstant("DIMENSION_ELEMENT_BREACH_TOTAL")
        ]
      };

      if (this.hasModule(["risk"])) {
        profileBasicParams.surveyTypes.push(
          this.getConstant("SURVEY_KYMATIO_INSIDER_RED")
        );
        profileBasicParams.surveyTypes.push(
          this.getConstant("SURVEY_KYMATIO_INSIDER_GREEN")
        );
      }
      axios
        .all([
          getStakeholder("people", this.$route.params.id, {
            stakeholderDepartmentName: true,
            email: true,
            avatar: true
          }),
          getInteractions(this.$route.params.id, true),
          getInteractions(this.$route.params.id, false),
          getProfile(this.$route.params.id, profileBasicParams),
          getStakeholderAssets(this.$route.params.id),
          getStakeholderBreaches(this.$route.params.id, "people")
        ])
        .then(
          axios.spread(
            (
              stkResp,
              interactionsResp,
              sessionsResp,
              profileResp,
              assetsResp,
              userBreachesResp
            ) => {
              this.interactions = interactionsResp.data.records;
              this.sessions = sessionsResp.data.records;
              this.breaches = userBreachesResp.data.records;
              var stakeholder = stkResp.data.records;
              this.user = {
                name: stakeholder.name + " " + stakeholder.surname,
                isBoss: stakeholder.isBoss === "1",
                department: stakeholder.stakeholderDepartmentName,
                email: stakeholder.email,
                avatar: stakeholder.avatar,
                risk: extractValueFromProfile(
                  profileResp.data.records,
                  "DIMENSION_GROUP_RISK",
                  "SURVEY_KYMATIO_GRI_GLOBAL",
                  "dimensionValue",
                  interactionsResp.data.records,
                  "DIMENSION_ELEMENT_GENERAL_RISK"
                ),
                hazard: extractValueFromProfile(
                  profileResp.data.records,
                  "DIMENSION_GROUP_HAZARD",
                  "SURVEY_KYMATIO_GRI_GLOBAL",
                  "dimensionValue",
                  interactionsResp.data.records,
                  "DIMENSION_ELEMENT_GENERAL_HAZARD"
                ),
                desirability: extractValueFromProfile(
                  profileResp.data.records,
                  "DIMENSION_GROUP_DESIRABILITY",
                  "SURVEY_KYMATIO_GRI_GLOBAL",
                  "dimensionValue",
                  interactionsResp.data.records,
                  "DIMENSION_ELEMENT_DESIRABILITY"
                ),
                liar: extractValueFromProfile(
                  profileResp.data.records,
                  "DIMENSION_GROUP_LIAR",
                  "SURVEY_KYMATIO_GRI_GLOBAL",
                  "dimensionValue",
                  interactionsResp.data.records,
                  "DIMENSION_ELEMENT_LIAR"
                ),
                cyber: extractValueFromProfile(
                  profileResp.data.records,
                  "DIMENSION_GROUP_CIBERSECURITY",
                  "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
                  "dimensionValue",
                  interactionsResp.data.records,
                  "DIMENSION_ELEMENT_CIBER_RESULTS"
                ),
                wellbeing: extractValueFromProfile(
                  profileResp.data.records,
                  "DIMENSION_GROUP_WORKING",
                  "SURVEY_KYMATIO_CLIMATE_GLOBAL",
                  "dimensionValue",
                  interactionsResp.data.records,
                  "DIMENSION_ELEMENT_CLIMATE_LEVEL"
                ),
                insider: extractValueFromProfile(
                  profileResp.data.records,
                  "DIMENSION_GROUP_INSIDER",
                  "SURVEY_KYMATIO_GRI_GLOBAL",
                  "dimensionValue",
                  interactionsResp.data.records,
                  "DIMENSION_ELEMENT_PRINCIPAL_INSIDER"
                ),
                aggRisk: extractValueFromProfile(
                  profileResp.data.records,
                  "DIMENSION_GROUP_RISK",
                  "SURVEY_KYMATIO_GRI_GLOBAL",
                  "dimensionValue",
                  interactionsResp.data.records,
                  "DIMENSION_ELEMENT_AGGREGATED_RISK"
                ),
                avgRisk: extractValueFromProfile(
                  profileResp.data.records,
                  "DIMENSION_GROUP_RISK",
                  "SURVEY_KYMATIO_GRI_GLOBAL",
                  "dimensionValue",
                  interactionsResp.data.records,
                  "DIMENSION_ELEMENT_AVERAGE_RISK"
                ),
                abs: extractValueFromProfile(
                  profileResp.data.records,
                  "DIMENSION_GROUP_ABS",
                  "SURVEY_KYMATIO_BREACH_CORPORATE",
                  "dimensionValueExtra",
                  interactionsResp.data.records,
                  "DIMENSION_ELEMENT_BREACH_TOTAL"
                ),
                pendingInteractions: _.filter(
                  this.sessions,
                  interaction =>
                    (interaction.surveyStatus === "AVAILABLE" ||
                      interaction.surveyStatus === "PROGRESS") &&
                    (parseInt(interaction.familyTypeId) ===
                      parseInt(this.getConstant("SURVEYTYPE_KYMATIO_DIRECT")) ||
                      parseInt(interaction.familyTypeId) ===
                        parseInt(
                          this.getConstant("SURVEYTYPE_KYMATIO_CYBERSECURITY")
                        ) ||
                      parseInt(interaction.familyTypeId) ===
                        parseInt(
                          this.getConstant("SURVEYTYPE_KYMATIO_CLIMA_PLUS")
                        ) ||
                      parseInt(interaction.familyTypeId) ===
                        parseInt(
                          this.getConstant("SURVEYTYPE_KYMATIO_WELCOME")
                        ))
                ),
                assets: assetsResp.data.records
              };
              this.user.progress = extractValueFromProfile(
                profileResp.data.records,
                "DIMENSION_GROUP_SCORING",
                "SURVEY_KYMATIO_SCORE_GLOBAL",
                "dimensionValue",
                interactionsResp.data.records,
                "DIMENSION_ELEMENT_KYMATIO_GLOBAL_NOTE"
              );
              this.selected = _.trimStart(this.$route.hash, "#") || "timeline";
            }
          )
        );
    }
  },
  mounted() {
    if (this.isConstantsActive) this.loadUser();
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) this.loadUser();
    }
  }
};
</script>

<style lang="scss" scoped>
.selected-mod:after {
  content: " ";
  display: block;
  position: absolute;
  height: 2px;
  background: #c0c9d0;
  width: 20%;
  // left: 100%;
  bottom: calc(0% - 10px);
}

.table > tbody > tr > td {
  max-width: 300px;
}
</style>
