<template>
  <b-card no-body class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">
        {{ $t("dashboard.sessions.title") }}
      </h3>
    </div>
    <!--end::Header-->
    <div class="card-body pt-5">
      <div class="timeline timeline-5">
        <div class="timeline-items">
          <!--begin::Item-->
          <div
            class="timeline-item mt-6 text-left"
            v-for="session in formatedSessions"
            :class="{ 'no-line': isWelcome(session.family) }"
            :key="session.id"
            @click="goToSession(session)"
            :style="{ cursor: 'pointer' }"
          >
            <!--begin::Icon-->
            <div :class="`timeline-media bg-light-${getStatusClass(session)}`">
              <span
                :class="
                  `svg-icon svg-icon-2x svg-icon-${getStatusClass(session)}`
                "
              >
                <simple-svg
                  :src="
                    require(`@/assets/images/icons/${getIconByType(
                      session.family
                    )}`)
                  "
                />
              </span>
            </div>
            <!--end::Icon-->

            <!--begin::Info-->
            <div
              :class="
                `timeline-desc timeline-desc-light-${getStatusClass(session)}`
              "
            >
              <span
                class="font-weight-bolder d-flex align-items-center justify-content-between"
              >
                <span
                  :class="`text-dark-75`"
                  v-html="session.name"
                  v-if="
                    !controls ||
                      parseInt(session.family) ===
                        parseInt(getConstant('SURVEYTYPE_KYMATIO_WELCOME'))
                  "
                />
                <span v-else :class="`text-dark-75`" v-html="session.name" />
                <b-dropdown
                  class="dropdown dropdown-inline ml-2"
                  toggle-class="btn-icon"
                  variant="clean"
                  menu-class="dropdown-menu p-0 m-0"
                  size="sm"
                  offset="10"
                  right
                  :no-caret="true"
                  v-if="
                    controls &&
                      session.status !== 'unavailable' &&
                      !(
                        parseInt(session.family) ===
                          parseInt(getConstant('SURVEYTYPE_KYMATIO_WELCOME')) &&
                        session.status === 'finish'
                      )
                  "
                >
                  <template slot="button-content">
                    <i class="ki ki-bold-more-hor"></i>
                  </template>
                  <template slot="default">
                    <ul class="navi navi-hover">
                      <li
                        class="navi-item"
                        v-if="session.status === 'available'"
                      >
                        <a @click="goToSession(session)" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-next"></i>
                          </span>
                          <span class="navi-text">{{
                            $t("dashboard.sessions.actions.start")
                          }}</span>
                        </a>
                      </li>
                      <li
                        class="navi-item"
                        v-if="session.status === 'progress'"
                      >
                        <a @click="goToSession(session)" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-fast-next"></i>
                          </span>
                          <span class="navi-text">{{
                            $t("dashboard.sessions.actions.continue")
                          }}</span>
                        </a>
                      </li>
                      <li class="navi-item" v-if="session.status === 'finish'">
                        <router-link
                          :to="{
                            name: 'TrainingDetail',
                            params: { id: session.surveyEntityId }
                          }"
                          class="navi-link"
                        >
                          <span class="navi-icon">
                            <i class="flaticon2-graphic-1"></i>
                          </span>
                          <span class="navi-text">{{
                            $t("dashboard.sessions.actions.report")
                          }}</span>
                        </router-link>
                      </li>
                    </ul>
                  </template>
                </b-dropdown>
              </span>
              <div
                class="font-weight-bolder d-flex align-items-top justify-content-between pb-1"
              >
                <span
                  :class="
                    `label label-lg label-outline-${getStatusClass(
                      session
                    )} font-weight-bolder label-inline ml-2`
                  "
                >
                  {{ $t(`dashboard.sessions.status.${session.status}`) }}
                </span>
                <p
                  class="font-weight-normal text-dark-50"
                  v-html="session.calendar"
                />
              </div>
            </div>
            <!--end::Info-->
          </div>
          <!--end::Item-->
        </div>
      </div>
    </div>
    <div class="card-footer border-top-0 pt-0" v-if="hasPager">
      <div class="d-flex justify-content-around">
        <div class="d-flex flex-column">
          <b-button
            pill
            :variant="canGoUp ? 'light-primary' : 'disabled'"
            :disabled="!canGoUp"
            class="btn-icon"
            @click="up"
          >
            <i class="fa fa-chevron-up"></i>
          </b-button>
        </div>
        <div class="d-flex flex-column">
          <b-button
            pill
            :variant="canGoDown ? 'light-primary' : 'disabled'"
            :disabled="!canGoDown"
            class="btn-icon"
            @click="down"
          >
            <i class="fa fa-chevron-down"></i>
          </b-button>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import { mapGetters, mapActions } from "vuex";

import { SimpleSVG } from "vue-simple-svg";

export default {
  name: "SessionsTimeline",
  props: {
    sessions: {
      type: Array,
      required: true
    },
    limit: {
      type: Number,
      default: 6,
      required: false
    },
    controls: {
      type: Boolean,
      default: true,
      required: false
    },
    selectable: {
      type: Boolean,
      default: false,
      required: false
    },
    selected: {
      required: false
    }
  },
  components: {
    "simple-svg": SimpleSVG
  },
  data() {
    return {
      page: 0
    };
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"]),
    ...mapGetters("Chatbot", ["bots"]),
    hasPager() {
      return this.sessions.length > this.limit;
    },
    formatedSessions() {
      var result = [];
      _.forEach(
        _.slice(
          this.sessions,
          this.limit * this.page,
          this.limit * (this.page + 1)
        ),
        o => {
          result.push({
            id: o.surveyEntityId,
            surveyId: o.surveyTypeId,
            family: o.familyTypeId,
            userStartDate: o.userStartDate,
            dateStatus: o.dateStatus,
            services: o.services,
            helperSurveyTypeId: o.helperSurveyTypeId,
            entityId: o.entityId,
            calendar: moment(o.dateStatus).format("ll"),
            surveyEntityId: o.surveyEntityId,
            status: _.toLower(o.surveyStatus),
            name: o.surveyName
          });
        }
      );

      return result;
    },
    canGoUp() {
      return this.page > 0 && this.sessions.length > 0;
    },
    canGoDown() {
      return (
        this.sessions.length > 0 &&
        (this.page + 1) * this.limit < this.sessions.length
      );
    }
  },
  methods: {
    ...mapActions("Chatbot", ["activate", "callBot"]),
    up() {
      if (this.canGoUp) this.page--;
    },
    down() {
      if (this.canGoDown) this.page++;
    },
    isWelcome(sessionFamily) {
      return (
        parseInt(sessionFamily) ===
        parseInt(this.getConstant("SURVEYTYPE_KYMATIO_WELCOME"))
      );
    },
    getIconByType(type) {
      var result = "";
      switch (parseInt(type)) {
        case parseInt(this.getConstant("SURVEYTYPE_KYMATIO_BURNOUT")):
          result = "fire.svg";
          break;
        case parseInt(this.getConstant("SURVEYTYPE_KYMATIO_CLIMA_PLUS")):
          result = "home-heart.svg";
          break;
        case parseInt(this.getConstant("SURVEYTYPE_KYMATIO_CYBERSECURITY")):
          result = "shield-protected.svg";
          break;
        case parseInt(this.getConstant("SURVEYTYPE_KYMATIO_WELCOME")):
          result = "tools.svg";
          break;
        default:
          result = "mail-locked.svg";
          break;
      }
      return result;
    },
    getStatusClass(session) {
      var result = "default";
      switch (session.status) {
        case "finish":
          result = "success";
          break;
        case "available":
          result = "warning";
          break;
        case "progress":
          result = "info";
          break;
        case "unavailable":
          result = "default";
          break;
      }
      return result;
    },
    goToSession(survey) {
      if (this.selectable) {
        if (
          survey.status === "unavailable" &&
          moment().isBefore(survey.userStartDate)
        ) {
          this.$swal(
            this.$t("training.isBlocked.title"),
            this.$t("training.isBlocked.description", {
              date: moment(survey.userStartDate).fromNow(true)
            }),
            "warning"
          );
        } else if (survey.status === "finish") {
          if (this.$route.name !== "TrainingDetail") {
            this.$router.push({
              name: "TrainingDetail",
              params: { id: survey.surveyEntityId }
            });
          }
        } else {
          this.activate(survey.surveyEntityId);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
span {
  white-space: nowrap;
}
</style>
