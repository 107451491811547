var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loaded)?_c('b-row',[(_vm.wellbeing.labels.length > 0)?_c('b-col',{attrs:{"col":"","xl":"4","lg":"3","md":"12","sm":"12","xs":"12"}},[_c('div',{staticClass:"wellbeing-cart-container h-100"},[_c('chart',{attrs:{"title":_vm.$t('tables.detail.user.evolution', {
              module: _vm.$t('generic.modules.wellbeing.title')
            }),"series":[
            {
              name: _vm.$t('generic.modules.wellbeing.title'),
              data: _vm.wellbeing.series
            }
          ],"categories":_vm.wellbeing.labels,"dates":_vm.wellbeing.dates,"discrete":_vm.wellbeing.discrete,"colors":{
            light: ['#cacbec'],
            normal: ['#a1a3d3']
          },"height":350}})],1)]):_vm._e(),(_vm.actions.length > 0)?_c('b-col',{attrs:{"col":"","cols":"auto","xl":"8","lg":"9","md":"12","sm":"12","xs":"12"}},[_c('tabs',{attrs:{"tabs":_vm.actions}})],1):_vm._e(),(_vm.actions.length === 0 && _vm.wellbeing.labels.length === 0)?_c('b-col',[_c('empty-card',{attrs:{"type":"user.wellbeing"}})],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }