<template>
  <b-card
    no-body
    :class="
      'card-custom card-stretch gutter-b ' + (half ? 'card-stretch-half' : '')
    "
  >
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">
        {{ $tc("dashboard.alerts.title", total, { total: total }) }}
      </h3>
    </div>
    <!--begin::Body-->
    <div class="card-body pt-0">
      <!--begin::Item-->
      <div
        :class="
          `d-flex align-items-center pb-3 is-link text-hover-${alert.level}`
        "
        v-for="alert in alerts"
        :key="alert.type + alert.level"
        @click="goToDetail(alert)"
      >
        <!--begin::Content-->
        <div class="d-flex align-items-center flex-grow-1">
          <!--begin::Checkbox-->
          <div :class="`symbol symbol-45 symbol-white mr-4 flex-shrink-0`">
            <div class="symbol-label">
              <span :class="`svg-icon svg-icon-2x`">
                <!--begin::Svg Icon | path:/metronic/theme/html/demo9/dist/assets/media/svg/icons/Shopping/Barcode-read.svg-->
                <simple-svg
                  :src="require(`@/assets/images/icons/${alert.icon}`)"
                />
                <!--end::Svg Icon-->
              </span>
              <span
                :class="
                  `has-badge font-size-xs label label-pill font-weight-bolder label-${alert.level}`
                "
              >
                {{ alert.total }}
              </span>
            </div>
          </div>
          <!--end::Checkbox-->
          <!--begin::Section-->
          <div
            class="d-flex flex-wrap align-items-center justify-content-between w-100 text-left"
          >
            <!--begin::Info-->
            <div class="d-flex flex-column align-items-cente py-2 w-auto">
              <!--begin::Title-->
              <span
                :class="`text-dark-75 font-weight-bold font-size-lg mb-1`"
                v-html="
                  $tc(
                    `dashboard.alerts.${alert.type}.${type}.${alert.level}`,
                    alert.counter !== undefined ? alert.counter : alert.total,
                    { total: alert.total }
                  )
                "
              />
              <!--end::Title-->
            </div>
            <!--end::Info-->
          </div>
          <!--end::Section-->
        </div>
        <div class="ml-2" v-if="alert.to">
          <b-button
            tag="div"
            @click="
              alert.chat
                ? activate(alert.chat)
                : alert.to
                ? $router.push(alert.to)
                : ''
            "
            :class="`bg-transparent border-0 svg-icon svg-icon-2x`"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 01-1.474-1.352l4.853-5.294-5.323-5.323z" fill="#000" fill-rule="nonzero"/><path d="M5.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 11-1.474-1.352l4.853-5.294-5.323-5.323z" fill="#000" fill-rule="nonzero" opacity=".3"/></g></svg>
          </b-button>
        </div>
        <!--end::Content-->
      </div>
      <!--end::Item-->
      <achievements-alerts
        :link="link"
        :achievements="achievements"
        v-if="hasModule(['gaming_achievements'])"
      ></achievements-alerts>
    </div>
  </b-card>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import { SimpleSVG } from "vue-simple-svg";
import AchievementsAlerts from "./AchievementsAlerts.component";

export default {
  name: "DashboardAlerts",
  components: {
    "simple-svg": SimpleSVG,
    AchievementsAlerts
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    limit: {
      type: Number,
      required: false,
      default: 100
    },
    half: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters("Profile", ["achievements"]),
    ...mapGetters("Auth", ["hasModule"]),
    alerts() {
      return _.slice(this.data, 0, this.limit);
    },
    total() {
      var data = _.countBy(this.data, el => (el.levelNo === 3 ? false : true));
      return data.true;
    },
    grouped() {
      return _.groupBy(this.data, "type");
    }
  },
  methods: {
    ...mapActions("Chatbot", ["activate"]),
    goToDetail(alert) {
      alert.chat
        ? this.activate(alert.chat)
        : alert.to
        ? this.$router.push(alert.to)
        : "";
    }
  }
};
</script>

<style lang="scss" scoped>
.has-badge {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0.7rem;
}
.is-link {
  cursor: pointer;
}
</style>
